import { cellStyle } from "@welborne/component-library";
import { useState } from "react";
import { Product } from "../../services/api/types/products";
import { capitalize } from "../../services/capitalize";
import { roundToTwo } from "../../services/formatNumber";
import { Image } from "../../services/Image";

interface ColumnType<T> {
  field: keyof T;
  [index: string]: any;
}

const AltImage: React.FC<{ src: string; alt: string }> = ({ src, alt }) => {
  const [iconUrl, setIconUrl] = useState(src);
  return (
    <Image
      src={iconUrl}
      style={{
        marginRight: "9.22px",
        height: "32px",
        maxHeight: "54px",
        width: "40px",
        borderRadius: "7px",
        objectFit: "cover",
      }}
      onError={() => {
        setIconUrl(alt);
      }}
    />
  );
};

// define your columns
export const getColumns = (token: string): Array<ColumnType<Product>> => {
  return [
    {
      title: "Product Name",
      field: "name",
      cellStyle: cellStyle,
      render: (product: Product) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <AltImage
              src={product.iconUrl}
              alt={`${process.env.REACT_APP_API_URL}/products/${product.id}/images/main-icon`}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                fontWeight: "600" as any,
                lineHeight: "18px",
                color: "#373F41",
              }}
            >
              <div>{`${product.name}`}</div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Category",
      field: "productCategoryName",
      cellStyle: cellStyle,
    },
    {
      title: "Status",
      field: "status",
      searchable: false,
      align: "left",
      cellStyle: cellStyle,
      render: (product: Product) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            {product.status && capitalize(product.status)}
          </div>
        );
      },
    },
    {
      title: "Base Price",
      field: "basePrice",
      searchable: false,
      cellStyle: cellStyle,
      render: (product: Product) => {
        if (product.costVariants?.[0])
          return "$" + roundToTwo(product.costVariants?.[0].price || 0);
        return "N/A";
      },
    },
    {
      title: "Date Created",
      field: "createdAt",
      searchable: false,
      align: "left",
      cellStyle: cellStyle,
      render: (product: Product) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            {new Date(product.createdAt).toLocaleDateString("en-us")}
          </div>
        );
      },
    },
  ];
};
