import Repository from "./Repository";
import { Company, EditCompany } from "./types/companies";
const path = "/companies";

const CompaniesRepository = {
  list() {
    return Repository.get<{ companies: Array<Company> }>(`${path}`);
  },
  create(data: Omit<EditCompany, "status">) {
    return Repository.post<Company>(`${path}`, data);
  },
  update(id: string, data: Partial<EditCompany>) {
    return Repository.put<Company>(`${path}/${id}`, data);
  },
  get(id: string) {
    return Repository.get<Company>(`${path}/${id}`);
  },
  updatePicture(id: string, photo: File) {
    const form = new FormData();
    form.append("main-icon", photo);
    return Repository.post(`${path}/${id}/images`, form);
  },
};

export default CompaniesRepository;
