import { LoginContainer, LoginSide, Main } from "./Login.styles";

export const LoginLayout: React.FC = (props) => {
  return (
    <Main>
      <LoginContainer>{props.children}</LoginContainer>
      <LoginSide />
    </Main>
  );
};
