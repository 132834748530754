import React from "react";
import { ToastTypes } from "@welborne/component-library";

export interface ToastType {
  message: string;
  type: ToastTypes;
  timeOut?: number;
}
export type Toaster = Array<ToastType>;
export const defaultToaster: Toaster = [];

/**
 * A helper to create a Context and Provider with no upfront default value, and
 * without having to check for undefined all the time.
 */
export function createCtx<A>(defaultValue: A) {
  type UpdateType = React.Dispatch<React.SetStateAction<typeof defaultValue>>;
  const defaultUpdate: UpdateType = () => defaultValue;
  const ctx = React.createContext({
    state: defaultValue,
    update: defaultUpdate,
  });
  function Provider(props: React.PropsWithChildren<{}>) {
    const [state, update] = React.useState(defaultValue);
    return <ctx.Provider value={{ state, update }} {...props} />;
  }
  return [ctx, Provider] as const; // alternatively, [typeof ctx, typeof Provider]
}

export const [ToasterContext, ToasterProvider] = createCtx(defaultToaster);
