import Repository from "./Repository";
import { Manufacturer } from "./types/manufacturers";
const path = "/manufacturers";

const ManufacturersRepository = {
  list() {
    return Repository.get<{ manufacturers: Array<Manufacturer> }>(`${path}`);
  },
  // create(data: Omit<EditCompany, "status">) {
  //   return Repository.post<Company>(`${path}`, data);
  // },
  // update(id: string, data: Partial<EditCompany>) {
  //   return Repository.put<Company>(`${path}/${id}`, data);
  // },
  // get(id: string) {
  //   return Repository.get<Company>(`${path}/${id}`);
  // },
};

export default ManufacturersRepository;
