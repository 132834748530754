import { Form } from "formik";
import styled from "styled-components";

export const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: 20px;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;
