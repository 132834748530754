type NumberType = "percent" | "dollar";

export const formatNumber = (number: number, type: NumberType) => {
  //fix number to being a 0 if it is not a number
  if (typeof number === "string") {
    number = parseFloat(number);
  }
  if (typeof number !== "number") number = 0;
  if (type === "percent") {
    return Math.round(number * 100) + "%";
  } else if (type === "dollar") {
    return "$" + Math.round(number).toLocaleString();
  }
};

export function roundToTwo(num: number) {
  const rounded = +(Math.round(parseFloat(num + "e+2")) + "e-2");
  if (isNaN(rounded)) return undefined;
  else return rounded;
}
