import styled from "styled-components";

export const Page = styled.div`
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-areas: "header" "main" "footer";
  overflow-x: hidden;
`;

export const Body = styled.div`
  overflow-x: auto;
`;
