import { compactHeaderStyle, compactCellStyle, cellStyle } from "@welborne/component-library"
import {} from "@welborne/component-library/dist/Table/Table.styles"
import { Job } from "../../services/api/types/jobs"
import { capitalize } from "../../services/capitalize"
import { roundToTwo } from "../../services/formatNumber"

interface ColumnType<T> {
  field: keyof T | string
  [index: string]: any
}
const padding = "2px"

// define your columns
export const getColumns = (token: string): Array<ColumnType<Job>> => {
  return [
    {
      title: "Job Number",
      field: "jobNumber",
      cellStyle: compactCellStyle,
      headerStyle: compactHeaderStyle
    },
    {
      title: "Customer Name",
      field: "clientName",
      cellStyle: compactCellStyle,
      headerStyle: compactHeaderStyle
    },
    {
      title: "Job Type",
      field: "type",
      cellStyle: compactCellStyle,
      headerStyle: compactHeaderStyle
    },
    {
      title: "Tech Name",
      field: "tech.name",
      cellStyle: cellStyle
    },
    {
      title: "Company Name",
      field: "companyName",
      cellStyle: compactCellStyle,
      headerStyle: compactHeaderStyle
    },
    {
      title: "Address",
      field: "streetAddress",
      cellStyle: compactCellStyle,
      headerStyle: compactHeaderStyle
    },
    {
      title: "City",
      field: "city",
      cellStyle: compactCellStyle,
      headerStyle: compactHeaderStyle
    },
    {
      title: "State",
      field: "state",
      cellStyle: compactCellStyle,
      headerStyle: compactHeaderStyle
    },
    {
      title: "Zip Code",
      field: "zip",
      cellStyle: compactCellStyle,
      headerStyle: compactHeaderStyle
    },
    {
      title: "Quote Total",
      field: "totalPrice",
      cellStyle: compactCellStyle,
      headerStyle: compactHeaderStyle,
      render: (job: Job) => {
        return "$" + roundToTwo(job.totalPrice || 0)
      }
    },
    {
      title: "Status",
      field: "status",
      searchable: false,
      align: "left",
      cellStyle: compactCellStyle,
      headerStyle: compactHeaderStyle,
      render: (job: Job) => {
        return <div style={{ display: "flex", alignItems: "center" }}>{job.status && capitalize(job.status)}</div>
      }
    },
    {
      title: "Date Created",
      field: "createdAt",
      searchable: false,
      align: "left",
      cellStyle: compactCellStyle,
      headerStyle: compactHeaderStyle,
      render: (job: Job) => {
        return <div style={{ display: "flex", alignItems: "center" }}>{new Date(job.createdAt).toLocaleDateString("en-us")}</div>
      }
    },
    {
      title: "Date Modified",
      field: "updatedAt",
      searchable: false,
      align: "left",
      cellStyle: compactCellStyle,
      headerStyle: compactHeaderStyle,
      render: (job: Job) => {
        console.log(job)
        return <div style={{ display: "flex", alignItems: "center" }}>{new Date(job.updatedAt).toLocaleDateString("en-us")}</div>
      }
    }
  ]
}
