import {
  Button,
  CenterPopup,
  FileUpload,
  Table,
  TableTypes,
  TextInput,
} from "@welborne/component-library";
import { getColumns } from "./DoorItemColumn";
import { History } from "history";
import { useHistory, useParams } from "react-router-dom";
import { useContext, useEffect, useRef, useState } from "react";
import { Api } from "../../services/api";
import { DoorItem } from "../../services/api/types/products";
import { Container } from "../Page.styles";
import { ButtonContainer } from "../Product/ProductForm.styles";
import { UploadContainer, UploadText } from "../Product/ProductTable.styles";
import { Formik, Form } from "formik";
import React from "react";
import { User, UserContext } from "../../services/UserContext";
import axios from "axios";

export function setCompanyId(
  history: History,
  companyId: string | undefined,
  newCompanyId: string
) {
  if (!newCompanyId) return;
  let location = history.location.pathname;
  //if companyId isn't set just append
  if (!companyId) {
    const index = location.indexOf("/doorItems");
    location =
      location.substring(0, index) +
      "/" +
      newCompanyId +
      location.substring(index);
    history.push(location);
  } else {
    location = location.replace(companyId, newCompanyId);
    history.push(location);
  }
}

enum UploadState {
  start,
  upload,
  confirm,
  success,
  error,
}

interface SpreadsheetUpload {
  spreadsheet: Map<string, File>;
  company: string;
}

const UploadSpreadsheet: React.FC<{
  currentUser: User;
  companies: Map<string, string>;
  companyName?: string;
}> = ({ currentUser, companies, companyName }) => {
  const [open, setOpen] = useState(false);
  const [uploadState, setUploadState] = useState(UploadState.start);
  const [error, setError] = useState("");
  const values: SpreadsheetUpload = {
    spreadsheet: new Map(),
    company: companyName || currentUser.company.name,
  };

  const uploadSpreadsheet = async (values: SpreadsheetUpload) => {
    const csv = values.spreadsheet.values().next().value;
    try {
      const res = await Api.products.uploadCsv(
        companies.get(values.company) || currentUser.company.id,
        csv
      );
      setUploadState(UploadState.success);
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.data?.detail) {
        const errorStr: string = error.response.data.detail;
        console.log(errorStr);
        setError(errorStr);
        setUploadState(UploadState.error);
      }
    }
  };
  //when closing upload reset to start and
  const closeUpload = (open = false) => {
    setUploadState(UploadState.start);
    setOpen(open);
  };

  return (
    <>
      <Button onClick={() => setOpen(true)} outline>
        Upload Spreadsheet
      </Button>
      {open && (
        <CenterPopup setOpen={closeUpload}>
          <Formik initialValues={values} onSubmit={uploadSpreadsheet}>
            <Form>
              {uploadState === UploadState.start ? (
                <UploadContainer>
                  <UploadText>
                    Are you sure? Uploading a spreadsheet will override ALL
                    current products and pricing
                  </UploadText>
                  <ButtonContainer style={{ justifyContent: "center" }}>
                    <Button onClick={() => setUploadState(UploadState.upload)}>
                      Yes
                    </Button>
                    <Button outline onClick={() => closeUpload()}>
                      No
                    </Button>
                  </ButtonContainer>
                </UploadContainer>
              ) : uploadState === UploadState.upload ? (
                <UploadContainer>
                  {currentUser.isSuperAdmin ? (
                    <TextInput
                      name={`company`}
                      key={`company`}
                      label="Company"
                      placeholder="Company"
                      options={Array.from(companies.keys())}
                    />
                  ) : null}
                  <FileUpload
                    name="spreadsheet"
                    handleContinue={() => setUploadState(UploadState.confirm)}
                    handleCancel={() => closeUpload()}
                  />
                </UploadContainer>
              ) : uploadState === UploadState.confirm ? (
                <UploadContainer>
                  <UploadText>
                    Are you sure?
                    <br />
                    This cannot be undone.
                  </UploadText>
                  <ButtonContainer style={{ justifyContent: "center" }}>
                    <Button type="submit">Yes</Button>
                    <Button outline onClick={() => closeUpload()}>
                      No
                    </Button>
                  </ButtonContainer>
                </UploadContainer>
              ) : uploadState === UploadState.success ? (
                <UploadContainer>
                  <UploadText>
                    Success! <br />
                    All product updates have been applied successfully
                  </UploadText>
                  <ButtonContainer style={{ justifyContent: "center" }}>
                    <Button onClick={() => closeUpload()}>Ok</Button>
                  </ButtonContainer>
                </UploadContainer>
              ) : uploadState === UploadState.error ? (
                <UploadContainer>
                  <UploadText>
                    Error! <br />
                    {error}
                  </UploadText>
                  <ButtonContainer style={{ justifyContent: "center" }}>
                    <Button onClick={() => closeUpload()}>Ok</Button>
                  </ButtonContainer>
                </UploadContainer>
              ) : null}
            </Form>
          </Formik>
        </CenterPopup>
      )}
    </>
  );
};

export const DoorItemTable = () => {
  const history = useHistory();
  const [loaded, setLoaded] = useState(false);
  const [doorItems, setDoorItems] = useState<Array<DoorItem>>([]);
  const selectedRows = useRef<Array<DoorItem>>([]);
  const currentUser: User = useContext(UserContext);
  const [companies, setCompanies] = useState<Map<string, string>>(new Map());
  const [company, setCompany] = useState<string>(currentUser.company.name);
  const { companyId } = useParams<{ companyId: string }>();

  async function getCompanies() {
    if (!currentUser.isSuperAdmin) return;
    const res = await Api.companies.list();
    const companies = new Map<string, string>();
    for (const company of res.data.companies) {
      companies.set(company.name, company.id);
      if (companyId === company.id) setCompany(company.name);
    }
    setCompanies(companies);
  }

  const getDoorItems = async () => {
    try {
      //clear doorItems while loading new doorItems
      setDoorItems([]);

      if (companyId) {
        const res = await Api.products.listDoorItems(companyId);
        setDoorItems(res.data.doorPermutations);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    //if there isn't a company id default to current users company
    if (!companyId) setCompanyId(history, companyId, currentUser.company.id);

    Promise.all([getCompanies(), getDoorItems()]).then(() => {
      setLoaded(true);
    });
  }, [companyId, currentUser]);

  const values = { company };

  const extraButtons = (
    <ButtonContainer>
      {currentUser.isSuperAdmin ? (
        <Formik initialValues={values} onSubmit={() => {}}>
          {({ setFieldValue }) => (
            <Form>
              <TextInput
                setFieldValue={(field: string, value: any) => {
                  const newCompanyId = companies.get(value) || companyId;
                  setCompanyId(history, companyId, newCompanyId);
                  setFieldValue(field, value);
                }}
                name={`company`}
                key={`company`}
                placeholder="Company"
                options={Array.from(companies.keys())}
              />
            </Form>
          )}
        </Formik>
      ) : null}
      <UploadSpreadsheet
        currentUser={currentUser}
        companies={companies}
        companyName={values.company}
      />
    </ButtonContainer>
  );

  return (
    <Container>
      {loaded && (companies.size > 0 || !currentUser.isSuperAdmin) ? (
        <Table
          searchable
          selectedRows={selectedRows}
          title={TableTypes.DoorItems}
          tableType={TableTypes.DoorItems}
          userPermissions={[]}
          columns={getColumns("")}
          isLoading={!loaded}
          data={doorItems}
          extraButtons={extraButtons}
        />
      ) : null}
    </Container>
  );
};
