export interface Company {
  id: string;
  status: CompanyStatus;
  name: string;
  streetAddress: string;
  city: string;
  state: string;
  zipcode: string;
  quoteLifetimeInDays: number;
  logoUrl: string;
  userCount: number;
  salesTaxPercentage: number;
  createdAt: Date | string;
  updatedAt: Date;
  manufacturerId: string;
}
export interface EditCompany {
  name: string;
  streetAddress: string;
  city: string;
  state: string;
  zipcode: string;
  quoteLifetimeInDays: number;
  status: CompanyStatus;
  salesTaxPercentage: number;
  manufacturerId: string;
}

export enum CompanyStatus {
  active = "active",
  inactive = "inactive",
}
