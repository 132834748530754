import { Table, TableTypes } from "@welborne/component-library";
import { getColumns } from "./JobColumn";
import { useEffect, useState } from "react";
import { Api } from "../../services/api";
import { Container } from "../Page.styles";
import { Job } from "../../services/api/types/jobs";

export const JobTable = () => {
  const [jobs, setJobs] = useState<Array<Job>>([]);
  const [loaded, setLoaded] = useState(false);

  const getJobs = async () => {
    try {
      const res = await Api.jobs.list();
      setJobs(res.data.entries);
    } catch (error) {
      console.error(error);
    }
    setLoaded(true);
  };
  useEffect(() => {
    getJobs();
  }, []);

  return (
    <Container>
      {loaded && (
        <Table
          searchable
          title={TableTypes.Jobs}
          tableType={TableTypes.Jobs}
          userPermissions={[]}
          columns={getColumns("")}
          isLoading={false}
          data={jobs}
        />
      )}
    </Container>
  );
};
