import { ToastProps, ToastTypes } from "@welborne/component-library";
import { useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Api } from "../../services/api";
import { UpdateRole } from "../../services/api/types/roles";
import { ToasterContext } from "../../services/ToasterContext";
import { RoleForm, RoleFormTitles } from "./RoleForm";

export const EditRole = () => {
  const { roleId } = useParams<{ roleId: string }>();
  const { update } = useContext(ToasterContext);
  const history = useHistory();

  const submit = (values: UpdateRole) => {
    const role: UpdateRole = {
      name: values.name,
      status: values.status,
      description: values.description,
      permissions: values.permissions,
    };
    Api.roles
      .update(roleId, role)
      .then((res) => {
        update((oldState: Array<ToastProps>) => {
          oldState.push({
            message: "Role Successfully Saved",
            type: ToastTypes.success,
          });
          return [...oldState];
        });
        history.push("/roles");
      })
      .catch((error) => {
        console.error(error, values);
        update((oldState: Array<ToastProps>) => {
          oldState.push({
            message: error.toString(),
            type: ToastTypes.error,
          });
          return [...oldState];
        });
      });
  };

  return <RoleForm title={RoleFormTitles.edit} submit={submit} />;
};
