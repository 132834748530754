import { colorTheme, textTheme } from "@welborne/component-library";
import styled from "styled-components";
import { Image } from "../../services/Image";

export const Container = styled.div`
  padding: 40px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const JobHead = styled.h1`
  color: ${colorTheme.grayDark};
  ${textTheme.h3};
  margin: 0;
`;
export const HouseInfo = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 60px;
  width: 100%;
`;
export const HouseData = styled.div``;
export const HouseDataSub = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
`;
export const HouseImg = styled(Image)`
  max-width: 440px;
  max-height: 230px;
  place-self: end;
`;

export const DataHead = styled.h3`
  color: ${colorTheme.primary};
  ${textTheme.subtitle1};
  text-transform: uppercase;
  margin: 0;
`;
export const Data = styled.p`
  color: ${colorTheme.black};
  ${textTheme.body2};
  padding-bottom: 5px;
  border-bottom: 1px solid ${colorTheme.grayLight};
`;
export const DoorInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid ${colorTheme.grayLight};
`;
export const DoorHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
export const DoorHead = styled.h2`
  color: ${colorTheme.primary};
  ${textTheme.h5};
  text-transform: uppercase;
  margin: 0;
`;
export const DoorPrice = styled.h3`
  color: ${colorTheme.black};
  ${textTheme.h6};
  font-style: normal;
  font-weight: bold;
  margin: 0;
`;

export const DoorItems = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
`;
export const DoorItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;
export const ItemText = styled.p`
  color: ${colorTheme.black};
  ${textTheme.small}
  font-weight: normal;
  margin: 0;
`;
export const ItemImg = styled(Image)`
  width: 68px;
`;
export const ItemPrice = styled.p`
  color: ${colorTheme.black};
  ${textTheme.subtitle2};
  margin: 0;
`;

export const SubHead = styled.h6`
  color: ${colorTheme.black};
  ${textTheme.small};
  font-weight: bold;
  margin: 0;
`;
export const InsulationData = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;
export const Thermometers = styled.div`
  display: flex;
  flex-direction: row;
`;
export const ThermometerIcon = styled(Image)``;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding-bottom: 20px;
  border-bottom: 1px solid ${colorTheme.grayLight};
  & textarea {
    color: ${colorTheme.grayDark};
    ${textTheme.body2};
    max-width: 730px;
  }
`;
export const Note = styled.p`
  color: ${colorTheme.grayDark};
  ${textTheme.body2};
  padding: 20px;
  border: 1px solid ${colorTheme.grayLight};
  border-radius: 4px;
  margin: 0;
  max-width: 730px;
  box-sizing: border-box;
`;

export const PhotosContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  align-items: center;
`;

export const PhotoContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  max-width: 350px;
  height: max-content;

  border: 1px solid ${colorTheme.grayLight};
  box-sizing: border-box;
  box-shadow: 0px 8px 16px rgba(100, 100, 100, 0.15);
  border-radius: 4px;
  padding: 15px;
  background-color: ${colorTheme.white};
`;
export const Thumbnail = styled(Image)`
  width: 40px;
  height: 40px;
  border-radius: 4px;
  align-self: center;
`;
export const Name = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;
export const NameTitle = styled.p`
  margin: 0;
  padding: 0;
  color: ${colorTheme.grayDark};
  ${textTheme.body2};
  font-weight: bold;
`;
export const NameSubTitle = styled.p`
  margin: 0;
  padding: 0;
  color: ${colorTheme.gray};
  ${textTheme.small};
`;
export const ViewIcon = styled.div`
  width: 22px;
  min-width: 22px;
  color: ${colorTheme.primary};
  align-self: center;
  justify-self: end;
`;

export const Pricing = styled(DoorHeader)``;
export const PriceHolder = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
export const PriceContainer = styled.div<{ expired?: boolean }>`
  width: max-content;
  margin-left: auto;
  ${(props) =>
    props.expired
      ? `
    padding: 0 5px;
    border: 1px solid ${colorTheme.error};
  `
      : ""}
`;
export const PriceBreakdown = styled.div`
  display: grid;
  justify-content: end;
  grid-template-columns: max-content max-content;
  gap: 10px;
  width: 100%;
  justify-self: end;
`;
export const PriceText = styled.p<{ expired?: boolean }>`
  color: ${(props) => (props.expired ? colorTheme.error : colorTheme.black)};
  ${textTheme.h5};
  font-weight: normal;
  text-align: end;
  margin: 0;
`;
export const Price = styled.strong<{ expired?: boolean }>`
  color: ${(props) => (props.expired ? colorTheme.error : colorTheme.primary)};
`;

export const Override = styled.div``;
export const OverrideText = styled.p`
  color: ${colorTheme.black};
  ${textTheme.body2};
  margin: 0;
  width: max-content;
`;
export const OverrideError = styled(OverrideText)`
  color: ${colorTheme.error};
`;

export const PopupText = styled.p`
  color: ${colorTheme.grayDark};
  ${textTheme.subtitle2};
  text-align: center;
`;

export const Popup = styled.div`
  display: flex;
  flex-direction: column;
  place-items: center;
  background: ${colorTheme.white};
  padding: 25px;
  max-width: 550px;
`;

export const PopupButtons = styled.div`
  display: flex;
  flex-direction: row;
  place-items: center;
  gap: 20px;
`;
export const OverrideButtons = styled(PopupButtons)`
  flex-direction: column;
  width: max-content;
`;

export const OverrideContainer = styled(Container)`
  place-items: center;
`;

export const ItemContainer = styled.div`
  width: 100%;
  max-width: 800px;
`;
export const DoorItemContainer = styled(ItemContainer)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
export const HeadContainer = styled.div`
  display: flex;
  flex-direction: row;
  place-content: space-between;
`;
export const OverrideSpacer = styled.div`
  width: 90%;
  border-bottom: 1px solid ${colorTheme.grayLight};
`;
export const TotalOverride = styled.div`
  padding: 20px;
  border: 1px solid ${colorTheme.grayLight};
  border-radius: 10px;
  width: min-content;
  place-self: end;
`;
export const TotalOverrideText = styled.p`
  margin: 0;
  ${textTheme.body2};
  color: ${colorTheme.black};
`;
export const GrandCustomPricing = styled.h6`
  margin: 0;
  ${textTheme.subtitle2};
`;
export const OverrideAdjustments = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 10px;
`;

export const PreviewImg = styled.img`
  margin: 0;
  align-self: start;
  justify-self: start;
`;

export const OverlayContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
  cursor: pointer;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const PreviewContainer = styled.div`
  cursor: auto;
  z-index: 10;
  width: fit-content;
  display: flex;
  flex-direction: column;
`;
