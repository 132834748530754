import { cellStyle } from "@welborne/component-library";
import { Company } from "../../services/api/types/companies";
import { capitalize } from "../../services/capitalize";

interface ColumnType<T> {
  field: keyof T;
  [index: string]: any;
}

// define your columns
export const getColumns = (token: string): Array<ColumnType<Company>> => {
  return [
    {
      title: "Company Name",
      field: "name",
      cellStyle: cellStyle,
    },
    {
      title: "Address",
      field: "streetAddress",
      cellStyle: cellStyle,
    },
    {
      title: "City",
      field: "city",
      cellStyle: cellStyle,
    },
    {
      title: "State",
      field: "state",
      cellStyle: cellStyle,
    },
    {
      title: "Zip Code",
      field: "zipcode",
      type: "numeric",
      align: "left",
      cellStyle: cellStyle,
    },
    {
      title: "Status",
      field: "status",
      searchable: false,
      align: "left",
      cellStyle: cellStyle,
      render: (company: Company) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            {company.status && capitalize(company.status)}
          </div>
        );
      },
    },
    {
      title: "Users",
      field: "userCount",
      searchable: false,
      type: "numeric",
      align: "left",
      cellStyle: cellStyle,
    },
    {
      title: "Date Created",
      field: "createdAt",
      searchable: false,
      align: "left",
      cellStyle: cellStyle,
      render: (company: Company) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            {new Date(company.createdAt).toLocaleDateString("en-us")}
          </div>
        );
      },
    },
  ];
};
