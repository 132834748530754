import { Table, TableTypes, TextInput } from "@welborne/component-library";
import { getColumns } from "./ProductColumn";
import { History } from "history";
import { useHistory, useParams } from "react-router-dom";
import { useContext, useEffect, useRef, useState } from "react";
import { Api } from "../../services/api";
import { Product } from "../../services/api/types/products";
import { Container } from "../Page.styles";
import { ButtonContainer } from "./ProductForm.styles";
import { Formik, Form } from "formik";
import React from "react";
import { User, UserContext } from "../../services/UserContext";

export function setCompanyId(
  history: History,
  companyId: string | undefined,
  newCompanyId: string
) {
  if (!newCompanyId) return;
  let location = history.location.pathname;
  //if companyId isn't set just append
  if (!companyId) {
    const index = location.indexOf("/products");
    location =
      location.substring(0, index) +
      "/" +
      newCompanyId +
      location.substring(index);
    history.push(location);
  } else {
    location = location.replace(companyId, newCompanyId);
    history.push(location);
  }
}

export const ProductTable = () => {
  const history = useHistory();
  const [loaded, setLoaded] = useState(false);
  const [products, setProducts] = useState<Array<Product>>([]);
  const selectedRows = useRef<Array<Product>>([]);
  const currentUser: User = useContext(UserContext);
  const [companies, setCompanies] = useState<Map<string, string>>(new Map());
  const [company, setCompany] = useState<string>(currentUser.company.name);
  const { companyId } = useParams<{ companyId: string }>();

  async function getCompanies() {
    if (!currentUser.isSuperAdmin) return;
    const res = await Api.companies.list();
    const companies = new Map<string, string>();
    for (const company of res.data.companies) {
      companies.set(company.name, company.id);
      if (companyId === company.id) setCompany(company.name);
    }
    setCompanies(companies);
  }

  const getProducts = async () => {
    try {
      //clear products while loading new products
      setProducts([]);

      if (companyId) {
        const res = await Api.products.list(companyId);
        setProducts(res.data.products);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    //if there isn't a company id default to current users company
    if (!companyId) setCompanyId(history, companyId, currentUser.company.id);

    Promise.all([getCompanies(), getProducts()]).then(() => {
      setLoaded(true);
    });
  }, [companyId, currentUser]);

  const handleMassUpdate = () => {
    if (selectedRows.current.length) {
      const ids = selectedRows.current.map((value) => value.id);
      history.push(
        (history.location.pathname +=
          "/massUpdate?" + new URLSearchParams({ ids: ids.join(",") }))
      );
    }
  };

  const values = { company };

  const extraButtons = (
    <ButtonContainer>
      {currentUser.isSuperAdmin ? (
        <Formik initialValues={values} onSubmit={() => {}}>
          {({ setFieldValue }) => (
            <Form>
              <TextInput
                setFieldValue={(field: string, value: any) => {
                  const newCompanyId = companies.get(value) || companyId;
                  setCompanyId(history, companyId, newCompanyId);
                  setFieldValue(field, value);
                }}
                name={`company`}
                key={`company`}
                placeholder="Company"
                options={Array.from(companies.keys())}
              />
            </Form>
          )}
        </Formik>
      ) : null}
    </ButtonContainer>
  );

  return (
    <Container>
      {loaded && (companies.size > 0 || !currentUser.isSuperAdmin) ? (
        <Table
          searchable
          selectedRows={selectedRows}
          title={TableTypes.Products}
          tableType={TableTypes.Products}
          userPermissions={[]}
          columns={getColumns("")}
          isLoading={!loaded}
          data={products}
          handleDeleteSelected={handleMassUpdate}
          extraButtons={extraButtons}
        />
      ) : null}
    </Container>
  );
};
