import { createContext } from "react";

export interface User {
  id: string;
  company: {
    id: string;
    name: string;
  };
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  active: boolean;
  createdAt: string;
  updatedAt: string;
  status: number;
  avatar: string;
  isSuperAdmin: boolean;
}

export const defaultUser: User = {
  id: "",
  company: {
    id: "",
    name: "",
  },
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  active: false,
  createdAt: "",
  updatedAt: "",
  status: 0,
  avatar: "",
  isSuperAdmin: false,
};

export const UserContext = createContext(defaultUser);
