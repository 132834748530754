import Repository from "./Repository";
import {
  RoleDetails,
  RolePermissionDetails,
  UpdateRole,
  UpdateUsersRole,
} from "./types/roles";
const path = "/roles";

const RolesRepository = {
  list() {
    return Repository.get<{ roles: Array<RoleDetails> }>(`${path}`);
  },
  create(data: UpdateRole) {
    return Repository.post<RoleDetails>(`${path}`, data);
  },
  update(id: string, data: Partial<UpdateRole>) {
    return Repository.put<RoleDetails>(`${path}/${id}`, data);
  },
  updateUsers(id: string, data: UpdateUsersRole) {
    return Repository.post<RoleDetails>(`${path}/${id}/users`, data);
  },
  get(id: string) {
    return Repository.get<RoleDetails>(`${path}/${id}`);
  },
  delete(roleIds: Array<string>) {
    return Repository.delete<RoleDetails>(`${path}`, { data: { roleIds } });
  },
  permissionsList() {
    return Repository.get<{ permissions: Array<RolePermissionDetails> }>(
      `${path}/permissions/list`
    );
  },
};

export default RolesRepository;
