import { cellStyle, colorTheme, textTheme } from "@welborne/component-library";
import { UserDetails } from "../../services/api/types/users";
import { getUserInitials } from "./UserForm";
import styled from "styled-components";
import { useState } from "react";
import { Image } from "../../services/Image";
import { roundToTwo } from "../../services/formatNumber";

const AvatarDiv = styled.div`
  margin-right: 9px;
  height: 32px;
  width: 40px;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const AvatarPic = styled(Image)`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

export const AvatarText = styled.h3`
  margin: 0;
  padding: 0;
  width: max-content;
  height: max-content;
  color: ${colorTheme.black};
  ${textTheme.subtitle2};
  text-align: center;
`;

const UserAvatar: React.FC<{ src: string; initials: string }> = ({
  src,
  initials,
}) => {
  const [useInitials, setUseInitials] = useState(false);
  return (
    <AvatarDiv>
      {!useInitials && src !== "null" ? (
        <AvatarPic src={src} onError={() => setUseInitials(true)} />
      ) : (
        <AvatarText>{initials}</AvatarText>
      )}
    </AvatarDiv>
  );
};

interface ColumnType<T> {
  field: keyof T | string; //TODO fix the dot keys
  [index: string]: any;
}

// define your columns
export const getColumns = (
  isRole: boolean = false
): Array<ColumnType<UserDetails>> => {
  const columns = [
    {
      title: "User",
      field: "firstName",
      cellStyle: cellStyle,
      render: (user: UserDetails) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <UserAvatar
              src={`${user.avatar}`}
              initials={getUserInitials(user)}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                fontWeight: "600" as any,
                lineHeight: "18px",
                color: "#373F41",
              }}
            >
              <div>{`${user.firstName} ${user.lastName}`}</div>
            </div>
          </div>
        );
      },
      customFilterAndSearch: (search: string, rowData: UserDetails) => {
        const name = `${rowData.firstName} ${rowData.lastName}`;
        return name.toLocaleUpperCase().search(search.toLocaleUpperCase()) > -1;
      },
    },
    {
      title: "Company",
      field: "company.name",
      searchable: true,
      cellStyle: cellStyle,
    },
    {
      title: "Role",
      field: "role.name",
      searchable: true,
      cellStyle: cellStyle,
    },
    {
      title: "Door Jobs",
      field: "doorJobs",
      searchable: false,
      type: "numeric",
      align: "left",
      cellStyle: cellStyle,
      customFilterAndSearch: (value: Array<number>, rowData: UserDetails) =>
        parseInt(rowData.doorJobs) >= value[0] &&
        parseInt(rowData.doorJobs) <= value[1],
    },
    {
      title: "Interior Jobs",
      field: "interiorJobs",
      searchable: false,
      type: "numeric",
      align: "left",
      cellStyle: cellStyle,
      customFilterAndSearch: (value: Array<number>, rowData: UserDetails) =>
        parseInt(rowData.interiorJobs) >= value[0] &&
        parseInt(rowData.interiorJobs) <= value[1],
    },
    {
      title: "Avg. Quote",
      field: "avgSale",
      searchable: false,
      align: "left",
      cellStyle: cellStyle,
      customFilterAndSearch: (value: Array<number>, rowData: UserDetails) =>
        parseInt(rowData.avgSale || "") >= value[0] &&
        parseInt(rowData.avgSale || "") <= value[1],
      render: (user: UserDetails) => {
        if (user.avgSale) {
          const num = parseFloat(user.avgSale);
          if (isNaN(num)) return user.avgSale;
          return "$" + roundToTwo(num);
        }
      },
    },
  ];
  if (isRole) return columns.slice(0, 2);
  return columns;
};
