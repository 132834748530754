import { NestedRoutes } from "@welborne/component-library";
import { RoleTable } from "./RoleTable";
import { AddRole } from "./AddRole";
import { EditRole } from "./EditRole";
import { AddRoleUser } from "./AddUser";

export const RoleRoutes: NestedRoutes = {
  name: "roles",
  path: "roles",
  component: RoleTable,
  children: [
    {
      name: "Add Role",
      path: "add",
      component: AddRole,
    },
    {
      name: "Role",
      path: ":roleId",
      children: [
        {
          name: "Edit Role",
          path: "edit",
          component: EditRole,
        },
        {
          name: "Add Users",
          path: "addUsers",
          component: AddRoleUser,
        },
      ],
    },
  ],
};
