import {
  Button,
  HorizontalTabs,
  RadioList,
  Table,
  TableTypes,
  TextInput,
  PermissionForm,
  VerticalTabs,
} from "@welborne/component-library";
import { TabType } from "@welborne/component-library/dist/HorizontalTabs/HorizontalTabs.types";
import { Formik, FormikErrors, FormikHelpers } from "formik";
import { useEffect, useRef, useState } from "react";
import { Api } from "../../services/api";
import { useHistory, useParams } from "react-router-dom";
import {
  ButtonContainer,
  FormContainer,
  StyledForm,
  TabContainer,
} from "./RoleForm.styles";
import { Container, HeaderContainer, HeaderText } from "../Page.styles";
import {
  RolePermissionDetails,
  RoleStatus,
  RoleUserDetails,
  UpdateRole,
  UpdateRolePermission,
} from "../../services/api/types/roles";
import { getColumns } from "../User/UserColumn";

export interface RoleFormType {
  name: string;
  description: string;
  status: string;
  permissions: RolePermissionDetails[];
}

export enum RoleFormTitles {
  add = "Add Role",
  edit = "Edit Role",
}

const StatusOptions = [RoleStatus[1], RoleStatus[2]] as const;

export const RoleForm: React.FC<{
  title: RoleFormTitles;
  submit: (values: UpdateRole) => void;
}> = ({ title, submit }) => {
  const history = useHistory();
  const [loaded, setLoaded] = useState(false);
  const [values, setValues] = useState<RoleFormType>({
    name: "",
    description: "",
    permissions: [],
    status: "Active",
  });
  const [permissions, setPermissions] = useState<Array<RolePermissionDetails>>(
    []
  );
  const [users, setUsers] = useState<Array<RoleUserDetails>>([]);
  const { roleId } = useParams<{ roleId: string }>();
  const selectedRows = useRef([]);

  const handleDeleteSelected = () => {
    if (!selectedRows.current.length) return;
    console.log(selectedRows.current);
  };
  const handleAddButton = () => {
    history.push(
      history.location.pathname.split("/").slice(0, -1).join("/") + "/addUsers"
    );
  };
  const columns = getColumns(true);
  const userPermissions: Array<string> = [];

  const setUpPermissionTabs = (permissions: Array<RolePermissionDetails>) => {
    const groups = permissions.filter((perm, i, permissions) => {
      const first = permissions.findIndex(
        (val) => val.groupName === perm.groupName
      );
      return first >= i;
    });
    return groups.map((first) => {
      const tab: TabType = {
        name: first.groupName,
        content: (
          <PermissionForm
            type={first.groupName}
            rolePermissions={permissions}
            setRolePermissions={setPermissions}
          />
        ),
      };
      return tab;
    });
  };

  const hTabs: Array<TabType> = [
    {
      name: "Permissions",
      content: (
        <TabContainer>
          <VerticalTabs tabs={setUpPermissionTabs(permissions)} />
        </TabContainer>
      ),
    },
  ];

  if (roleId) {
    hTabs.push({
      name: "Users",
      content: (
        <TabContainer>
          <Table
            userPermissions={userPermissions}
            tableType={TableTypes.RoleUsers}
            isLoading={!loaded}
            columns={columns}
            data={users}
            selectedRows={selectedRows}
            handleDeleteSelected={handleDeleteSelected}
            handleAddButton={handleAddButton}
          />
        </TabContainer>
      ),
    });
  }

  async function getPermissionsList() {
    try {
      const res = await Api.roles.permissionsList();
      setPermissions(res.data.permissions);
    } catch (error) {
      console.error(error);
      return false;
    }
    return true;
  }

  useEffect(() => {
    async function getRole(roleId: string) {
      if (!(await getPermissionsList())) {
        setLoaded(true);
        return;
      }
      if (!roleId) {
        setLoaded(true);
        return;
      }
      try {
        const res = await Api.roles.get(roleId);

        setValues({
          name: res.data.name,
          description: res.data.description,
          permissions: res.data.permissions,
          status: RoleStatus[res.data.status],
        });

        setPermissions(res.data.permissions);
        setUsers(res.data.users);

        setLoaded(true);
      } catch (error) {
        console.error(error);
        setLoaded(true);
      }
    }

    getRole(roleId);
  }, [roleId]);

  function returnToGrid() {
    history.push("/roles");
  }

  function preSubmit(
    oldValues: RoleFormType,
    helpers: FormikHelpers<RoleFormType>
  ) {
    const { permissions, ...rest } = oldValues;

    //check if anything is unset
    const errors: FormikErrors<RoleFormType> = {};
    if (!rest.name) {
      errors.name = "Required";
    }
    if (!rest.description) {
      errors.description = "Required";
    }
    if (Object.keys(errors).length !== 0) {
      helpers.setErrors(errors);
      return;
    }

    //format data if need
    const values: UpdateRole = {
      ...rest,
      permissions: permissions.map((permission) => {
        const perms: UpdateRolePermission = {
          permissionId: permission.id,
          hasPermission: permission.hasPermission,
        };
        return perms;
      }),
    };
    values.status = RoleStatus[values.status as unknown as RoleStatus];
    submit(values);
  }

  return (
    <Container>
      {loaded && (
        <Formik initialValues={values} onSubmit={preSubmit}>
          <StyledForm>
            <HeaderContainer>
              <HeaderText>{title}</HeaderText>
              <ButtonContainer>
                <Button type="submit">Save</Button>
                <Button type="button" outline onClick={() => returnToGrid()}>
                  Cancel
                </Button>
              </ButtonContainer>
            </HeaderContainer>
            <FormContainer>
              {roleId && (
                <div style={{ flexGrow: 1 }}>
                  <RadioList
                    name="status"
                    label="Status"
                    options={StatusOptions}
                  />
                </div>
              )}
              <div style={{ flexGrow: 1 }}>
                <TextInput name="name" label="Role Name" />
              </div>
              <div style={{ flexGrow: 2 }}>
                <TextInput name="description" label="Description" />
              </div>
            </FormContainer>
            <div style={{ marginTop: "50px" }}>
              <HorizontalTabs tabs={hTabs} />
            </div>
          </StyledForm>
        </Formik>
      )}
    </Container>
  );
};
