import CompaniesRepository from "./CompaniesRepository";
import JobsRepository from "./JobsRepository";
import UsersRepository from "./UsersRepository";
import ProductsRepository from "./ProductsRepository";
import ProductsCategoryRepository from "./ProductCategoryRepository";
import AuthRepository from "./AuthRepository";
import RolesRepository from "./RolesRepository";
import ManufacturersRepository from "./ManufacturersRepository";
import ImagesRepository from "./ImagesRepository";

export const Api = {
  auth: AuthRepository,
  users: UsersRepository,
  jobs: JobsRepository,
  companies: CompaniesRepository,
  products: ProductsRepository,
  productCategories: ProductsCategoryRepository,
  roles: RolesRepository,
  manufacturers: ManufacturersRepository,
  images: ImagesRepository,
};
