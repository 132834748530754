import {
  Button,
  Checkbox,
  RadioList,
  TextInput,
} from "@welborne/component-library";
import { Field, Formik } from "formik";
import { useContext, useEffect, useState } from "react";
import { Api } from "../../services/api";
import { useLocation, useHistory } from "react-router-dom";
import { ButtonContainer, StyledForm } from "./ProductForm.styles";
import { Container, HeaderContainer, HeaderText } from "../Page.styles";
import {
  BulkProductUpdate,
  Product,
  ProductStatus,
} from "../../services/api/types/products";
import { capitalize } from "../../services/capitalize";
import { UserContext } from "../../services/UserContext";
import {
  BodyContainer,
  ChangeContainer,
  ProductContainer,
  ProductGrid,
  ProductHead,
  ProductItem,
  ProductList,
  UpdateContainer,
} from "./MassUpdates.styles";
import { useParams } from "react-router-dom";

enum CostTypes {
  "Exact" = "Exact",
  "Change" = "Change",
}
enum IncreaseType {
  "Increase" = "Increase",
  "Decrease" = "Decrease",
}
enum PercentageType {
  "Percentage" = "Percentage",
  "Dollars" = "Dollars",
}

export interface MassFormType {
  productUids: Array<string>;
  costType: CostTypes;
  status: ProductStatus;
  productCategoryId: string;
  increase: IncreaseType;
  exactCost: string;
  percentage: PercentageType;
  value: string;
}

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const value: MassFormType = {
  productUids: [],
  productCategoryId: "",
  costType: CostTypes.Exact,
  status: ProductStatus.active,
  increase: IncreaseType.Increase,
  exactCost: "",
  percentage: PercentageType.Percentage,
  value: "",
};

export const MassUpdate: React.FC<{}> = () => {
  const currentUser = useContext(UserContext);
  const history = useHistory();
  const idString = useQuery().get("ids");
  const [loaded, setLoaded] = useState(false);
  const [products, setProducts] = useState<Array<Product>>([]);
  const [values, setValues] = useState<MassFormType>(value);
  const [productCategories, setProductCategories] = useState(
    new Map<string, string>()
  );
  const { companyId } = useParams<{ companyId: string }>();

  useEffect(() => {
    async function getCategories() {
      const res = await Api.productCategories.list();
      const categories = new Map<string, string>();
      const categoriesByID = new Map<string, string>();
      for (const category of res.data.productCategories) {
        categories.set(category.productCategory, category.id);
        categoriesByID.set(category.id, category.productCategory);
      }
      setProductCategories(categories);
      return categoriesByID;
    }

    async function getProduct(productIds: Array<string>) {
      await getCategories();
      if (!productIds.length) {
        setLoaded(true);
        return;
      }
      try {
        const products = [];
        for (const id of productIds) {
          const res = Api.products.get(id, companyId);
          products.push(res);
        }
        Promise.all(products).then((values) => {
          setProducts(values.map((value) => value.data));
          setLoaded(true);
        });
      } catch (error) {
        console.error(error);
        setLoaded(true);
      }
    }

    const productIds = idString ? idString.split(",") : [];
    setValues((oldValues) => {
      return { ...oldValues, productUids: productIds };
    });
    getProduct(productIds);
  }, [idString, currentUser]);

  function returnToGrid() {
    history.push("/products");
  }

  function preSubmit(oldValues: MassFormType) {
    const value: BulkProductUpdate = {
      productUids: oldValues.productUids,
      productCategoryId:
        productCategories.get(oldValues.productCategoryId) || "",
      status: ProductStatus.active,
      increase: values.increase === IncreaseType.Increase,
      exactCost:
        oldValues.costType === CostTypes.Exact
          ? parseFloat(oldValues.exactCost.replaceAll("$", ""))
          : undefined,
      percentage: values.percentage === PercentageType.Percentage,
      value: parseFloat(oldValues.value.replaceAll("$", "")),
    };
    Api.products
      .bulkUpdate(value)
      .then(() => history.push("/products"))
      .catch((error) => {
        console.error(error);
      });
  }

  return (
    <Container>
      {loaded && (
        <Formik initialValues={values} onSubmit={preSubmit}>
          {({ values }) => (
            <StyledForm>
              <HeaderContainer>
                <HeaderText>Mass Product Update</HeaderText>
                <ButtonContainer>
                  <Button type="submit">Update</Button>
                  <Button type="button" outline onClick={() => returnToGrid()}>
                    Cancel
                  </Button>
                </ButtonContainer>
              </HeaderContainer>
              <BodyContainer>
                <ProductList>
                  {products.map((product) => (
                    <ProductContainer key={product.name}>
                      <Field name="productUids">
                        {({ field }: any) => (
                          <Checkbox
                            {...field}
                            id={product.id}
                            value={product.id}
                            checked={values.productUids.includes(product.id)}
                          />
                        )}
                      </Field>
                      <label htmlFor={product.id}>
                        <ProductGrid>
                          <ProductHead>Product Name</ProductHead>
                          <ProductHead>Cost</ProductHead>
                          <ProductHead>Status</ProductHead>
                          <ProductItem>{product.name}</ProductItem>
                          <ProductItem>{product.basePrice}</ProductItem>
                          <ProductItem>
                            {capitalize(product.status)}
                          </ProductItem>
                        </ProductGrid>
                      </label>
                    </ProductContainer>
                  ))}
                </ProductList>
                <UpdateContainer>
                  <RadioList
                    name="status"
                    label="Status"
                    options={Object.keys(ProductStatus).map((stat) =>
                      capitalize(stat)
                    )}
                  />
                  <TextInput
                    name="productCategoryId"
                    label="Category"
                    options={Array.from(productCategories.keys())}
                  />
                  <RadioList
                    name="costType"
                    options={Object.keys(CostTypes)}
                    vertical
                    radioLabels={{
                      [CostTypes.Exact]: (
                        <TextInput
                          name="exactCost"
                          label="Exact Cost"
                          placeholder="Use this field to set all pricing to the same value"
                          type="cost"
                          min="0"
                          step="0.01"
                        />
                      ),
                      [CostTypes.Change]: (
                        <ChangeContainer>
                          <RadioList
                            name="increase"
                            label="Cost Change"
                            options={Object.keys(IncreaseType)}
                          />
                          <RadioList
                            name="percentage"
                            label="Value"
                            options={Object.keys(PercentageType)}
                          />
                          <TextInput
                            name="value"
                            type={
                              values.percentage === PercentageType.Percentage
                                ? "percentage"
                                : "cost"
                            }
                          />
                        </ChangeContainer>
                      ),
                    }}
                  />
                </UpdateContainer>
              </BodyContainer>
            </StyledForm>
          )}
        </Formik>
      )}
    </Container>
  );
};
