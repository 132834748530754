import {
  Button,
  ErrorFocus,
  RadioList,
  TextInput,
} from "@welborne/component-library";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import { Api } from "../../services/api";
import { useHistory, useParams } from "react-router-dom";
import {
  ButtonContainer,
  FormContainer,
  Horizontal,
  StyledForm,
} from "./CompanyForm.styles";
import { Container, HeaderContainer, HeaderText } from "../Page.styles";
import { States } from "../../services/states";
import { CompanyStatus } from "../../services/api/types/companies";
import { capitalize } from "../../services/capitalize";
import { AvatarUpload } from "../../services/AvatarUpload";
import * as Yup from "yup";

const quoteSuffix = " Days";
const quoteTimeOptions = [
  "30" + quoteSuffix,
  "45" + quoteSuffix,
  "60" + quoteSuffix,
  "90" + quoteSuffix,
] as const;

export const CompanyFormSchema = Yup.object({
  status: Yup.string().required("Required"),
  name: Yup.string().required("Required"),
  address: Yup.string().required("Required"),
  city: Yup.string().required("Required"),
  zipcode: Yup.string().required("Required"),
  state: Yup.string().required("Required"),
  quote: Yup.string().required("Required"),
  salesTaxPercentage: Yup.string().required("Required"),
  manufacturerId: Yup.string().required("Required"),
  avatar: Yup.mixed(),
});

export type CompanyFormType = Yup.InferType<typeof CompanyFormSchema>;

export enum CompanyFormTitles {
  add = "Add Company",
  edit = "Edit Company",
}

export const CompanyForm: React.FC<{
  title: CompanyFormTitles;
  submit: (values: CompanyFormType) => void;
}> = ({ title, submit }) => {
  const history = useHistory();
  const [loaded, setLoaded] = useState(false);
  const [manufacturers, setManufacturers] = useState(new Map<string, string>());
  const [values, setValues] = useState<CompanyFormType>({
    status: capitalize(CompanyStatus.active),
    name: "",
    address: "",
    city: "",
    zipcode: "",
    state: "",
    quote: "",
    salesTaxPercentage: "",
    manufacturerId: "",
    avatar: undefined,
  });
  const { companyId } = useParams<{ companyId: string }>();

  async function getManufacturers() {
    const manufacturersById = new Map<string, string>();
    const manufacturers = new Map<string, string>();
    try {
      const res = await Api.manufacturers.list();
      for (const manufacturer of res.data.manufacturers) {
        manufacturersById.set(manufacturer.id, manufacturer.name);
        manufacturers.set(manufacturer.name, manufacturer.id);
      }
    } catch (error) {
      console.error(error);
      setLoaded(true);
    }
    setManufacturers(manufacturers);
    return manufacturersById;
  }

  async function getCompany(companyId: string) {
    const manufacturersById = await getManufacturers();
    try {
      if (!companyId) {
        setLoaded(true);
        return;
      }
      const res = await Api.companies.get(companyId);
      let quote = res.data.quoteLifetimeInDays.toLocaleString() + quoteSuffix;
      if (res.data.quoteLifetimeInDays === 1) quote = quote.slice(0, -1);

      setValues({
        status: capitalize(CompanyStatus[res.data.status]),
        name: res.data.name,
        address: res.data.streetAddress,
        city: res.data.city,
        zipcode: res.data.zipcode,
        state: res.data.state,
        quote: quote,
        salesTaxPercentage: res.data.salesTaxPercentage?.toLocaleString() + "%",
        manufacturerId: manufacturersById.get(res.data.manufacturerId) || "",
        avatar: res.data.logoUrl,
      });
      setLoaded(true);
    } catch (error) {
      console.error(error);
      setLoaded(true);
    }
  }
  useEffect(() => {
    getCompany(companyId);
  }, [companyId]);

  function returnToGrid() {
    history.push("/companies");
  }

  function preSubmit(oldValues: CompanyFormType) {
    const values = { ...oldValues };
    values.manufacturerId = manufacturers.get(values.manufacturerId) || "";
    submit(values);
  }

  return (
    <Container>
      {loaded && (
        <Formik
          initialValues={values}
          validationSchema={CompanyFormSchema}
          onSubmit={preSubmit}
        >
          {({ setFieldValue, values }) => (
            <StyledForm>
              <ErrorFocus />
              <HeaderContainer>
                <HeaderText>{title}</HeaderText>
                <ButtonContainer>
                  <Button type="submit">Save</Button>
                  <Button type="button" outline onClick={() => returnToGrid()}>
                    Cancel
                  </Button>
                </ButtonContainer>
              </HeaderContainer>
              <Horizontal>
                <AvatarUpload
                  name="avatar"
                  setFieldValue={setFieldValue}
                  placeholder={values.name
                    .split(" ")
                    .map((word) => (word.length ? word[0] : ""))
                    .slice(0, 3)
                    .join("")
                    .toUpperCase()}
                />
                <FormContainer style={{ width: "436px" }}>
                  {companyId && (
                    <RadioList
                      name="status"
                      label="Status"
                      options={Object.keys(CompanyStatus).map((stat) =>
                        capitalize(stat)
                      )}
                    />
                  )}
                  <TextInput name="name" label="Company Name" />
                  <TextInput name="address" label="Address" />
                  <TextInput name="city" label="City" />
                  <TextInput name="zipcode" label="Zip Code" />
                  <TextInput
                    name="state"
                    label="State"
                    options={Object.keys(States)}
                  />
                  <TextInput
                    name="quote"
                    label="Quote Valid For"
                    options={quoteTimeOptions}
                  />
                  <TextInput
                    name="salesTaxPercentage"
                    label="Sales Tax Percentage"
                    type="percentage"
                  />
                  <TextInput
                    name="manufacturerId"
                    label="Manufacturer"
                    options={Array.from(manufacturers.keys())}
                  />
                </FormContainer>
              </Horizontal>
            </StyledForm>
          )}
        </Formik>
      )}
    </Container>
  );
};
