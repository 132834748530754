import styled from "styled-components";
import { colorTheme, textTheme } from "@welborne/component-library";
import { Image as ApiImage } from "../Image";

const borderRadius = "border-radius: 10px";

export const ImageContainer = styled.div<{ hasImg: boolean }>`
  ${borderRadius};
  width: 210px;
  height: 210px;
  position: relative;
  ${(props) =>
    !props.hasImg &&
    `
    background: ${colorTheme.grayLight};
  `}
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Container = styled.div``;

export const ButtonContainer = styled.button`
  position: absolute;
  border: 0;
  padding: 0;
  background: none;
  cursor: pointer;
  bottom: 0px;
  right: 0px;
  transform: translateX(50%);
`;

export const Image = styled(ApiImage)`
  ${borderRadius};
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const UploadContainer = styled.div`
  margin: 10px;
`;

export const PlaceHolderText = styled.h1`
  color: ${colorTheme.black};
  ${textTheme.h3};
  font-size: 64px;
`;
