import { NestedRoutes } from "@welborne/component-library";
import { ProductTable } from "./ProductTable";
import { AddProduct } from "./AddProduct";
import { EditProduct } from "./EditProduct";
import { MassUpdate } from "./MassUpdate";

export const ProductRoutes: NestedRoutes[] = [
  {
    name: "products",
    path: ":companyId/products",
    component: ProductTable,
    children: [
      {
        name: "Add Product",
        path: "add",
        component: AddProduct,
      },
      {
        name: "Mass Update",
        path: "massUpdate",
        component: MassUpdate,
      },
      {
        name: "Product",
        path: ":productId",
        children: [
          {
            name: "Edit Product",
            path: "edit",
            component: EditProduct,
          },
        ],
      },
    ],
  },
  {
    name: "products",
    path: "products",
    component: ProductTable,
    children: [
      {
        name: "Add Product",
        path: "add",
        component: AddProduct,
      },
      {
        name: "Mass Update",
        path: "massUpdate",
        component: MassUpdate,
      },
      {
        name: "Product",
        path: ":productId",
        children: [
          {
            name: "Edit Product",
            path: "edit",
            component: EditProduct,
          },
        ],
      },
    ],
  },
];
