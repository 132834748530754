import { useHistory, useParams } from "react-router-dom"
import { Api } from "../../services/api"
import { Button, CenterPopup, RadioList, TextInput } from "@welborne/component-library"
import { Fragment, useEffect, useState } from "react"
import { Form, Formik } from "formik"
import { GetJob, ItemDetails, ItemProduct, OverrideItemProductPrice } from "../../services/api/types/jobs"
import { DoorHead, DoorHeader, DoorItem, DoorItemContainer, DoorPrice, GrandCustomPricing, HeadContainer, HouseDataSub, ItemContainer, ItemImg, ItemPrice, ItemText, JobHead, OverrideAdjustments, OverrideContainer, OverrideSpacer, Popup, PopupButtons, PopupText, Price, PriceBreakdown, PriceContainer, PriceHolder, PriceText, TotalOverride, TotalOverrideText } from "./ViewJob.styles"
import doorFeature from "../../assets/Jobs/doorFeature.png"

const makePrice = (price: number | string = 0) => {
  if (typeof price === "string") price = parseFloat(price)
  return price.toLocaleString("en-us", { style: "currency", currency: "USD" })
}

enum DiscountTypes {
  Percent = "Percent",
  Dollars = "Dollars"
}
export interface Job extends GetJob {
  items: Item[]
  discount: number
  discountTotal: number
  discountType: DiscountTypes | ""
  discountAmount: number
  totalOverridenPrice?: number
}
export interface Item extends ItemDetails {
  products: Product[]
}
export interface Product extends ItemProduct {
  discountType?: DiscountTypes | ""
  discountAmount?: number
  discount?: number
}

export interface CalculateFieldProps<T> {
  fieldName: keyof T
  values: T
  calculationFunc: (values: T) => any
  setFieldValue: (field: keyof T, value: any) => void
}
function CalculateField<Type>(props: CalculateFieldProps<Type>) {
  const { values, fieldName, setFieldValue, calculationFunc } = props
  useEffect(() => {
    setFieldValue(fieldName, calculationFunc(values))
  }, [setFieldValue, values, fieldName, calculationFunc])

  return <></>
}

export const OverrideJob = () => {
  const { jobId } = useParams<{ jobId: string }>()
  const history = useHistory()
  const [popUpOpen, setPopUpOpen] = useState(false)
  const [lineItemDiscounts, setLineItemDiscounts] = useState(false)
  const [disableOverrideConfirm, setDisableOverrideConfirm] = useState(true)
  const [loaded, setLoaded] = useState(false)
  const [value, setValue] = useState<Job>({
    id: "",
    jobNumber: -1,
    clientName: "",
    streetAddress: "",
    zip: "",
    city: "",
    state: "",
    additionalNotes: "",
    administrativeNotes: "",
    items: [],
    photoUrl: "",
    photoName: "",
    additionalPhotoUrls: [],
    additionalPhotoNames: [],
    createdAt: new Date(),
    expired: false,
    total: 0,
    salesTax: 0,
    delivery: 0,
    installation: 0,
    grandTotal: 0,
    discount: 0,
    discountType: "",
    discountAmount: 0,
    discountTotal: 0
  })

  const [overiddenPrice, setOveriddenPrice] = useState<number | undefined>(0)
  const [originalPrice, setOriginalPrice] = useState<number | undefined>(0)
  const [disabledButton, setDisabled] = useState<boolean>(true)

  const getJob = async (jobId: string) => {
    const res = await Api.jobs.get(jobId)
    setValue({
      ...res.data,
      createdAt: new Date(res.data.createdAt),
      additionalNotes: res.data.additionalNotes || " ",
      administrativeNotes: res.data.administrativeNotes || "",
      discount: 0,
      discountType: "",
      discountTotal: 0,
      discountAmount: 0
    })
    setLoaded(true)
  }

  const getDiscountTotal = (values: Job) => {
    if (values.discountType) {
      setDisabled(false)
    }
    let arr = values.items[0].products

    let newArr = arr.find(x => x.discountType)

    if (newArr) {
      setLineItemDiscounts(true)
    } else {
      setLineItemDiscounts(false)
    }

    const totalDiscount = getDiscount({
      discountType: values.discountType,
      // discountAmount: values.discountAmount,
      price: values.grandTotal
    })
    return values.grandTotal - (totalDiscount + values.discount)
  }

  useEffect(() => {
    getJob(jobId)
  }, [jobId])

  useEffect(() => {
    Api.jobs.get(jobId).then(res => {
      setOveriddenPrice(res.data.grandTotalForOverridenPrice)
      setOriginalPrice(res.data.totalOverridenPrice as number)
    })
  }, [])

  const submit = (values: Job) => {
    //build discounts
    for (const door of values.items) {
      const productOverrides: OverrideItemProductPrice = { products: [] }
      for (const item of door.products) {
        if (item.discountAmount) {
          productOverrides.products.push({
            price: getDiscountPrice(item),
            projectItemProductUid: item.projectItemProductId
          })
        }
      }
      if (productOverrides.products.length) {
        Api.jobs.overrideProductPrice(jobId, door.id, productOverrides)
      }
    }
    history.push(history.location.pathname.split("/").slice(0, -1).join("/") + "/edit")
  }

  const handleOverride = () => {
    setPopUpOpen(true)
  }

  const getDiscountPrice = (item: Product) => {
    return item.price * item.quantity - getDiscount(item)
  }

  const getDiscount = (item: Pick<Product, "discountType" | "discountAmount" | "price">) => {
    if (item.discountType === DiscountTypes.Percent) {
      return item.price * ((item.discountAmount || 0) / 100)
    } else {
      return item.discountAmount || 0
    }
  }

  const makeDiscount = (values: Job) => {
    return values.items.reduce((total, item) => total + item.products.reduce((total, product) => total + getDiscount(product), 0), 0)
  }

  return (
    <>
      {loaded && (
        <Formik initialValues={value} onSubmit={submit}>
          {({ values, setFieldValue }) => (
            <Form>
              <OverrideContainer>
                <ItemContainer>
                  <HeadContainer>
                    <JobHead>Job Number: {values.jobNumber}</JobHead>
                    {/* {SaveButtons} */}
                  </HeadContainer>
                </ItemContainer>
                {values.items.map((door, doorIndex) => (
                  <Fragment key={door.id}>
                    <ItemContainer>
                      <DoorHeader>
                        <DoorHead>{door.name}</DoorHead>
                        {/* <DoorPrice>{makePrice(door.products.reduce((total, item) => total + parseFloat(item.price as unknown as string), 0))}</DoorPrice> */}
                      </DoorHeader>
                    </ItemContainer>
                    <ItemContainer>
                      <ItemText>
                        <strong>Size</strong>
                        <br />
                        {door.width} X {door.height}, Flat
                      </ItemText>
                    </ItemContainer>
                    <OverrideSpacer />
                    {door.products.map((item, itemIndex) => {
                      if (item.price === 0) return null
                      return (
                        <Fragment key={item.id}>
                          <DoorItemContainer>
                            <DoorItem key={item.id}>
                              <ItemText>
                                {item.productCategory} {item.quantity > 1 && `(${item.quantity})`}
                              </ItemText>
                              <ItemImg src={item.images.mainIconUrl || doorFeature} />
                              <ItemText>{item.name}</ItemText>
                              <ItemPrice>{makePrice(item.price * item.quantity)}</ItemPrice>
                            </DoorItem>
                            <PriceHolder>
                              <ItemText>Price Discount</ItemText>
                              <HouseDataSub>
                                <div
                                  style={{
                                    width: "min-content",
                                    minWidth: "110px"
                                  }}
                                >
                                  <TextInput name={`items[${doorIndex}].products[${itemIndex}].discountAmount`} type="number" value={(item.discountAmount || 0) as unknown as string} />
                                </div>
                                <RadioList name={`items[${doorIndex}].products[${itemIndex}].discountType`} options={Object.keys(DiscountTypes)} />
                              </HouseDataSub>
                            </PriceHolder>
                            <PriceHolder>
                              <ItemText style={{ textAlign: "end" }}>New Pricing</ItemText>
                              <ItemPrice style={{ textAlign: "end" }}>{makePrice(getDiscountPrice(item))}</ItemPrice>
                            </PriceHolder>
                          </DoorItemContainer>
                          <OverrideSpacer />
                        </Fragment>
                      )
                    })}
                  </Fragment>
                ))}
                <ItemContainer>
                  <PriceHolder>
                    <PriceBreakdown>
                      <PriceText>Total:</PriceText>
                      <PriceText>{makePrice(values.totalOverridenPrice ? values.totalOverridenPrice : values.total)}</PriceText>
                      <PriceText>Sales Tax:</PriceText>
                      <PriceText>{makePrice(values.salesTaxForOverridenPrice ? values.salesTaxForOverridenPrice : values.salesTax)}</PriceText>
                      <PriceText>Delivery:</PriceText>
                      <PriceText>{makePrice(values.delivery)}</PriceText>
                      <PriceText>Installation:</PriceText>
                      <PriceText>{makePrice(values.installation)}</PriceText>
                      <PriceText>Line Item Discounts applied:</PriceText>
                      <PriceText>({makePrice(values.discount)})</PriceText>
                      {/*
                      <PriceText>Quote Grand Total</PriceText>
                      <PriceText>
                        {makePrice(
                          values.total - values.discount + values.salesTax ||
                            0 + values.delivery ||
                            0 + values.installation ||
                            0
                        )}
                      </PriceText>
                        */}
                      <CalculateField calculationFunc={makeDiscount} fieldName="discount" values={values} setFieldValue={setFieldValue} />
                    </PriceBreakdown>

                    <TotalOverride>
                      <GrandCustomPricing>Grand Total Custom Pricing</GrandCustomPricing>
                      <HeadContainer style={{ padding: "40px 0" }}>
                        <TotalOverrideText>Original Total:</TotalOverrideText>
                        <TotalOverrideText>{makePrice(values.grandTotal)}</TotalOverrideText>
                      </HeadContainer>
                      <OverrideAdjustments>
                        <TotalOverrideText>Price Adjustment</TotalOverrideText>
                        <div
                          style={{
                            width: "min-content",
                            minWidth: "110px",
                            display: "flex",
                            gap: "10px",
                            alignItems: "center"
                          }}
                        >
                          {/* - */}
                          <TextInput name={"discountAmount"} type="number" value={(values.discountAmount || 0) as unknown as string} />
                        </div>
                        <RadioList name={"discountType"} options={Object.keys(DiscountTypes)} />
                        <PriceBreakdown>
                          <TotalOverrideText> Total Discounts:</TotalOverrideText>
                          <TotalOverrideText>{!values.discountType ? "$0.00" : makePrice(values.discountType === "Dollars" ? values.discountAmount + values.discount : values.total * (values.discountAmount / 100) + values.discount)}</TotalOverrideText>
                        </PriceBreakdown>
                      </OverrideAdjustments>
                    </TotalOverride>

                    <PriceBreakdown>
                      <div>
                        <PriceContainer>
                          <PriceBreakdown>
                            <PriceText>
                              <strong>Total After New Adjustments:</strong>
                            </PriceText>
                            <PriceText>
                              {/* <Price>discountTotal: {makePrice(values.discountTotal)}</Price>
                              <br></br>
                              <Price>grandTotalForOverridenPrice: {makePrice(values.grandTotalForOverridenPrice)}</Price>
                              <br></br> */}
                              <Price>{makePrice(overiddenPrice ? overiddenPrice : values.discountTotal)}</Price>
                              <CalculateField calculationFunc={getDiscountTotal} fieldName="discountTotal" values={values} setFieldValue={setFieldValue} />
                            </PriceText>
                          </PriceBreakdown>
                        </PriceContainer>
                      </div>
                    </PriceBreakdown>
                  </PriceHolder>
                </ItemContainer>
                <ItemContainer style={{ display: "flex", justifyContent: "end" }}>
                  <PopupButtons>
                    <Button
                      disabled={disabledButton && !lineItemDiscounts}
                      onClick={() => {
                        setDisabled(false)
                        Api.jobs.get(jobId).then(res => {
                          if (res.data.totalOverridenPrice) {
                            Api.jobs.overrideTotalPrice(jobId, res.data.totalOverridenPrice - (values.discountType === "Dollars" ? values.discountAmount + values.discount : values.total * (values.discountAmount / 100) + values.discount)).then(res => {
                              Api.jobs.get(jobId).then(res => {
                                setOveriddenPrice(res.data.grandTotalForOverridenPrice)
                                setDisabled(true)
                                setDisableOverrideConfirm(false)
                              })
                            })
                          } else {
                            Api.jobs.overrideTotalPrice(jobId, res.data.total - (values.discountType === "Dollars" ? values.discountAmount + values.discount : values.total * (values.discountAmount / 100) + values.discount)).then(res => {
                              Api.jobs.get(jobId).then(res => {
                                setOveriddenPrice(res.data.grandTotalForOverridenPrice)
                                setDisabled(true)
                                setDisableOverrideConfirm(false)
                              })
                            })
                          }
                        })
                      }}
                    >
                      Calculate New Total
                    </Button>
                    <Button disabled={disableOverrideConfirm} type="submit">
                      Update & Override
                    </Button>
                    <Button
                      outline
                      type="button"
                      onClick={() => {
                        handleOverride()
                      }}
                    >
                      Cancel
                    </Button>
                  </PopupButtons>
                </ItemContainer>
                {popUpOpen && (
                  <CenterPopup setOpen={setPopUpOpen}>
                    <Popup>
                      <PopupText>
                        You are about to cancel override current pricing. Your changes <Price expired>WILL NOT</Price> be saved. Would you like to proceed?
                      </PopupText>
                      <PopupButtons>
                        <Button
                          type="button"
                          onClick={() => {
                            Api.jobs.overrideTotalPrice(jobId, originalPrice as number)
                            history.push(history.location.pathname.split("/").slice(0, -1).join("/") + "/edit")
                          }}
                        >
                          Yes, Cancel
                        </Button>
                        <Button
                          outline
                          type="button"
                          onClick={() => {
                            setPopUpOpen(false)
                          }}
                        >
                          No, Continue Override
                        </Button>
                      </PopupButtons>
                    </Popup>
                  </CenterPopup>
                )}
              </OverrideContainer>
            </Form>
          )}
        </Formik>
      )}
    </>
  )
}
