import React from "react";
import { ToasterContext } from "../ToasterContext";
import { Toast, ToastProps } from "@welborne/component-library";
import { ToastType } from "../ToasterContext";

export const ToasterOven: React.FC = () => {
  const { state, update } = React.useContext(ToasterContext);

  return (
    <div>
      {state.map((toast: ToastProps) => (
        <Toast {...toast} />
      ))}
    </div>
  );
};
