import { useHistory, useParams } from "react-router-dom";
import { Api } from "../../services/api";
import houseImg from "../../assets/Jobs/house.png";
import thermometerIcon from "../../assets/Jobs/thermometer.svg";
import {
  Container,
  Data,
  DataHead,
  DoorHead,
  DoorHeader,
  DoorInfo,
  DoorItem,
  DoorItems,
  DoorPrice,
  HouseData,
  HouseDataSub,
  HouseImg,
  HouseInfo,
  InsulationData,
  ItemText,
  ItemImg,
  ItemPrice,
  JobHead,
  Thermometers,
  ThermometerIcon,
  Note,
  SubHead,
  Section,
  Pricing,
  PriceBreakdown,
  PriceText,
  Price,
  Override,
  OverrideText,
  OverrideError,
  PriceContainer,
  Popup,
  PopupText,
  PopupButtons,
  OverrideButtons,
  PriceHolder,
  PhotosContainer,
  PhotoContainer,
  Thumbnail,
  Name,
  NameTitle,
  NameSubTitle,
  ViewIcon,
} from "./ViewJob.styles";
import {
  Button,
  CenterPopup,
  TextArea,
  ToastProps,
  ToastTypes,
} from "@welborne/component-library";
import React, { useContext, useEffect, useState } from "react";
import { Form, Formik } from "formik";
import { Eye } from "../../assets/Jobs/Eye";
import { GetJob, UpdateJob } from "../../services/api/types/jobs";
import { OverlayContainer } from "./ViewJob.styles";
import { PreviewContainer } from "./ViewJob.styles";
import { Image } from "../../services/Image";
import { ToasterContext } from "../../services/ToasterContext";

const makePrice = (price: number | string = 0) => {
  if (typeof price === "string") price = parseFloat(price);
  return price.toLocaleString("en-us", { style: "currency", currency: "USD" });
};

export const ViewJob = () => {
  const { jobId } = useParams<{ jobId: string }>();
  const { update } = useContext(ToasterContext);
  const history = useHistory();
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [editAdminNote, setEditAdminNote] = useState(false);
  const [oldAdminNote, setOldAdminNote] = useState("");
  const [hasDiscount, setHasDiscount] = useState(false);
  const [hasNewQuote, setHasNewQuote] = useState(true);
  const [value, setValue] = useState<GetJob>({
    id: "",
    jobNumber: -1,
    clientName: "",
    streetAddress: "",
    zip: "",
    city: "",
    state: "",
    additionalNotes: "",
    administrativeNotes: "",
    items: [],
    photoUrl: "",
    photoName: "",
    additionalPhotoUrls: [],
    additionalPhotoNames: [],
    createdAt: new Date(),
    expired: false,
    total: 0,
    salesTax: 0,
    delivery: 0,
    installation: 0,
    grandTotal: 0,
  });
  const [previewImage, setPreviewImage] = useState<string>();
  const overlayClickHandler = (e: React.MouseEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement;
    if (target.id === "overlay") {
      setPreviewImage(undefined);
    }
  };

  const getJob = async (jobId: string) => {
    const res = await Api.jobs.get(jobId);
    setValue({
      ...res.data,
      createdAt: new Date(res.data.createdAt),
      additionalNotes: res.data.additionalNotes
        ? res.data.additionalNotes.trim()
        : "",
      administrativeNotes: res.data.administrativeNotes
        ? res.data.administrativeNotes.trim()
        : "",
    });
    setLoaded(true);
  };

  const updateData = async (values: GetJob) => {
    const data: UpdateJob = {
      clientName: values.clientName,
      streetAddress: values.streetAddress,
      city: values.city,
      state: values.state,
      zip: values.zip,
      additionalNotes: values.additionalNotes || undefined,
      administrativeNotes: values.administrativeNotes || undefined,
    };
    try {
      await Api.jobs.update(jobId, data);
      update((oldState: Array<ToastProps>) => {
        oldState.push({
          message: "Notes Successfully Saved",
          type: ToastTypes.success,
        });
        return [...oldState];
      });
    } catch (error) {
      console.error(error);
      update((oldState: Array<ToastProps>) => {
        oldState.push({
          message: "Notes Failed to Save",
          type: ToastTypes.error,
        });
        return [...oldState];
      });
    }
  };

  useEffect(() => {
    getJob(jobId);
  }, [jobId]);

  const setQuote = () => {
    setHasNewQuote(false);
  };

  const submit = (values: GetJob) => {
    console.log(values);
  };

  const placeThermometers = (num: number) => {
    const thermometers = [];
    for (let i = 0; i < num; i++) {
      thermometers.push(<ThermometerIcon src={thermometerIcon} key={i} />);
    }
    return thermometers;
  };

  const handleOverride = () => {
    setPopUpOpen(true);
  };

  const navToOverride = () => {
    history.push(
      history.location.pathname.split("/").slice(0, -1).join("/") + "/override"
    );
  };

  return (
    <>
      {loaded && (
        <Formik initialValues={value} onSubmit={submit}>
          {({ values, setFieldValue }) => (
            <Form>
              <Container>
                <JobHead>Job Number: {values.jobNumber}</JobHead>
                <HouseInfo>
                  <HouseData>
                    <DataHead>Client Name</DataHead>
                    <Data>{values.clientName}</Data>
                    <DataHead>Address</DataHead>
                    <Data>{values.streetAddress}</Data>
                    <HouseDataSub>
                      <div>
                        <DataHead>Zip</DataHead>
                        <Data>{values.zip}</Data>
                      </div>
                      <div>
                        <DataHead>City</DataHead>
                        <Data>{values.city}</Data>
                      </div>
                      <div>
                        <DataHead>State</DataHead>
                        <Data>{values.state}</Data>
                      </div>
                    </HouseDataSub>
                  </HouseData>
                  <HouseImg src={values.photoUrl || houseImg} />
                </HouseInfo>
                {values.items.map((door) => (
                  <DoorInfo key={door.id}>
                    <DoorHeader>
                      <DoorHead>{door.name}</DoorHead>
                      <DoorPrice>{makePrice(door.totalPrice)}</DoorPrice>
                    </DoorHeader>
                    {door.name.toLocaleLowerCase().includes("door") ? (
                      <ItemText>
                        <strong>Size</strong>
                        <br />
                        {door.width} X {door.height}, Flat
                      </ItemText>
                    ) : null}
                    <DoorItems>
                      {door.products
                        .filter(
                          (item) =>
                            !(
                              item.name === "Obstruction" &&
                              item.productCategory === "Obstruction" &&
                              item.price === 0
                            )
                        )
                        .map((item, i) => (
                          <DoorItem key={item.id + i}>
                            <ItemText>
                              {item.productCategory}{" "}
                              {item.quantity > 1 && `(${item.quantity})`}
                            </ItemText>
                            <ItemImg
                              src={
                                item.images.mainIconUrl ||
                                `${process.env.REACT_APP_API_URL}/products/${item.id}/images/main-icon`
                              }
                            />
                            <ItemText>{item.name}</ItemText>
                            <ItemPrice>
                              {makePrice(item.price * item.quantity)}
                            </ItemPrice>
                          </DoorItem>
                        ))}
                    </DoorItems>
                  </DoorInfo>
                ))}
                {values.additionalNotes && (
                  <Section>
                    <SubHead>Additional Notes</SubHead>
                    <Note>{values.additionalNotes}</Note>
                  </Section>
                )}
                {!!values.additionalPhotoUrls.length && (
                  <Section>
                    <SubHead>Additional Photos</SubHead>

                    <PhotosContainer>
                      {values.additionalPhotoUrls.map((photo, i) => (
                        <PhotoContainer key={i}>
                          <Thumbnail src={photo.url} />
                          <Name>
                            <NameTitle>
                              {values.additionalPhotoNames[i].name}
                            </NameTitle>
                            <NameSubTitle>
                              {values.additionalPhotoNames[i].createdAt}
                            </NameSubTitle>
                          </Name>
                          <ViewIcon
                            onClick={() => {
                              values.additionalPhotoNames[i].name ===
                              "main_thumbnail.jpeg"
                                ? setPreviewImage(values.photoUrl)
                                : setPreviewImage(photo.url);
                            }}
                          >
                            <Eye height="100%" width="100%" />
                          </ViewIcon>
                        </PhotoContainer>
                      ))}
                    </PhotosContainer>
                  </Section>
                )}
                <Section>
                  <SubHead>Administrative Notes</SubHead>
                  {values.administrativeNotes ? (
                    <>
                      {editAdminNote ? (
                        <>
                          <TextArea name="administrativeNotes" />
                          <PopupButtons style={{ marginTop: "10px" }}>
                            <Button
                              type="button"
                              onClick={() => {
                                updateData(values);
                                setEditAdminNote(false);
                              }}
                            >
                              Save
                            </Button>
                            <Button
                              type="button"
                              onClick={() => {
                                setFieldValue(
                                  "administrativeNotes",
                                  oldAdminNote
                                );
                                setEditAdminNote(false);
                              }}
                              outline
                            >
                              Cancel
                            </Button>
                          </PopupButtons>
                        </>
                      ) : (
                        <>
                          <Note>{values.administrativeNotes}</Note>
                          <PopupButtons style={{ marginTop: "10px" }}>
                            <Button
                              type="button"
                              onClick={() => {
                                setEditAdminNote(true);
                                setOldAdminNote(
                                  values.administrativeNotes || ""
                                );
                              }}
                            >
                              Edit
                            </Button>
                            <Button
                              type="button"
                              onClick={() => {
                                setFieldValue("administrativeNotes", "");
                                updateData({
                                  ...values,
                                  administrativeNotes: "",
                                });
                              }}
                              outline
                            >
                              Delete
                            </Button>
                          </PopupButtons>
                        </>
                      )}
                    </>
                  ) : (
                    <Button
                      type="button"
                      onClick={() => {
                        setFieldValue("administrativeNotes", " ");
                        setEditAdminNote(true);
                      }}
                    >
                      +Add Notes
                    </Button>
                  )}
                </Section>

                <Pricing>
                  {/* only show if status of job is not active */}
                  <Override>
                    <OverrideText>
                      Job created{" "}
                      <strong>
                        {values.createdAt.toLocaleDateString("en-US", {
                          month: "long",
                          day: "numeric",
                          year: "numeric",
                        })}
                      </strong>
                    </OverrideText>
                    {values.expired ? (
                      <>
                        <OverrideError>
                          Old pricing quote of $10,742.50{" "}
                          <strong>no longer valid.</strong>
                        </OverrideError>
                        {hasNewQuote && (
                          <OverrideText>
                            New price quote is{" "}
                            <Price>{makePrice(values.grandTotal)}</Price>
                          </OverrideText>
                        )}
                        <OverrideText>
                          Override price with other pricing table
                        </OverrideText>
                      </>
                    ) : (
                      <OverrideText>Pricing is up to Date.</OverrideText>
                    )}
                    <OverrideButtons>
                      <Button
                        type="button"
                        style={{ marginTop: "20px", width: "100%" }}
                        onClick={handleOverride}
                      >
                        Override
                      </Button>
                      {values.expired && hasNewQuote && (
                        <Button outline type="button" onClick={setQuote}>
                          Set Quote to New Price
                        </Button>
                      )}
                      {hasDiscount && (
                        <Button outline type="button" onClick={setQuote}>
                          Reset Pricing
                        </Button>
                      )}
                    </OverrideButtons>
                  </Override>

                  <PriceHolder>
                    <PriceBreakdown>
                      <PriceText>Total:</PriceText>
                      <PriceText>
                        {makePrice(
                          values.totalOverridenPrice
                            ? values.totalOverridenPrice
                            : values.total
                        )}
                      </PriceText>
                      <PriceText>Sales Tax:</PriceText>
                      <PriceText>
                        {makePrice(
                          values.salesTaxForOverridenPrice
                            ? values.salesTaxForOverridenPrice
                            : values.salesTax
                        )}
                      </PriceText>
                      <PriceText>Delivery:</PriceText>
                      <PriceText>{makePrice(values.delivery)}</PriceText>
                      <PriceText>Installation:</PriceText>
                      <PriceText>{makePrice(values.installation)}</PriceText>
                    </PriceBreakdown>
                    <div>
                      {values.expired && (
                        <PriceText expired>Expired Pricing</PriceText>
                      )}
                      <PriceContainer expired={values.expired}>
                        <PriceBreakdown>
                          <PriceText>
                            <strong>Grand Total:</strong>
                          </PriceText>
                          <PriceText>
                            <Price expired={values.expired}>
                              {makePrice(
                                values.grandTotalForOverridenPrice
                                  ? values.grandTotalForOverridenPrice
                                  : values.grandTotal
                              )}
                            </Price>
                          </PriceText>
                        </PriceBreakdown>
                      </PriceContainer>
                    </div>
                  </PriceHolder>
                </Pricing>

                {popUpOpen && (
                  <CenterPopup setOpen={setPopUpOpen}>
                    <Popup>
                      <PopupText>
                        You are about to override current pricing. Are you sure
                        you want to proceed?
                      </PopupText>
                      <PopupButtons>
                        <Button
                          type="button"
                          onClick={() => {
                            navToOverride();
                          }}
                        >
                          Yes
                        </Button>
                        <Button
                          outline
                          type="button"
                          onClick={() => {
                            setPopUpOpen(false);
                          }}
                        >
                          No
                        </Button>
                      </PopupButtons>
                    </Popup>
                  </CenterPopup>
                )}
                {previewImage ? (
                  <OverlayContainer
                    id={"overlay"}
                    onClick={overlayClickHandler}
                  >
                    <PreviewContainer>
                      <Image src={previewImage} />
                    </PreviewContainer>
                  </OverlayContainer>
                ) : null}
              </Container>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};
