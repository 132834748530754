import { Button, TextInput } from "@welborne/component-library";
import { FieldArray, Formik, FormikHelpers } from "formik";
import { useContext, useEffect, useState } from "react";
import { InputContainer, Hr, PText, StyledForm } from "./InviteUsers.styles";
import { Link, useHistory } from "react-router-dom";
import { Container, HeaderContainer, HeaderText } from "../Page.styles";
import { Api } from "../../services/api";
import axios from "axios";
import { InviteUserRes } from "../../services/api/types/users";
import React from "react";
import { User } from "../../services/UserContext";
import { UserContext } from "../../services/UserContext";
import * as Yup from "yup";

export const InviteUserFormSchema = Yup.object({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  role: Yup.string().required("Required"),
  email: Yup.string().required("Required"),
  phone: Yup.string().required("Required"),
  company: Yup.string().optional(),
});

export const InviteFormSchema = Yup.object({
  users: Yup.array().of(InviteUserFormSchema).min(1).required(),
});

export type InviteFormType = Yup.InferType<typeof InviteFormSchema>;

export const InviteUsers = () => {
  const [loaded, setLoaded] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [users, setUsers] = useState<InviteUserRes>();
  const [roles, setRoles] = useState<Map<string, string>>(new Map());
  const [companies, setCompanies] = useState<Map<string, string>>(new Map());
  const currentUser: User = useContext(UserContext);
  const history = useHistory();
  const values: InviteFormType = {
    users: [
      {
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        role: "",
        company: undefined,
      },
    ],
  };

  async function getRoles() {
    const res = await Api.roles.list();
    const roles = new Map<string, string>();
    for (const role of res.data.roles) {
      roles.set(role.name, role.id);
    }
    setRoles(roles);
  }

  async function getCompanies() {
    if (!currentUser.isSuperAdmin) return;
    const res = await Api.companies.list();
    const companies = new Map<string, string>();
    for (const company of res.data.companies) {
      companies.set(company.name, company.id);
    }
    setCompanies(companies);
  }

  useEffect(() => {
    //wait for api calls then set loaded
    Promise.all([getRoles(), getCompanies()]).then(() => setLoaded(true));
  }, [currentUser]);

  const submit = async (
    values: InviteFormType,
    helpers: FormikHelpers<InviteFormType>
  ) => {
    //copy values for modification
    const newValues = {
      users: values.users.map((user) => {
        return {
          first_name: user.firstName,
          last_name: user.lastName,
          email: user.email,
          phone: user.phone,
          role_id: roles.get(user.role) || "",
          company_id: currentUser.isSuperAdmin
            ? companies.get(user.company || "")
            : user.company,
        };
      }),
    };
    console.log(values, newValues);
    try {
      const res = await Api.users.invite(newValues);
      setUsers(res.data);
      setSubmitted(true);
    } catch (error) {
      console.error(error);
      if (axios.isAxiosError(error) && error.response?.data?.detail) {
        const errorStr: string = error.response.data.detail;
        if (
          errorStr.startsWith(
            "Users with following emails already have valid accounts"
          )
        ) {
          const errorEmails = errorStr
            .split(":")
            .pop() //Oh dear, remove before emails section of string
            ?.trim() //trim whitespace
            .slice(0, -1) //remove trailing .
            .split(","); //split by email
          if (errorEmails) {
            helpers.setErrors({
              users: values.users.map((user) => {
                //if the user's email is in the list add error
                if (errorEmails.includes(user.email)) {
                  return { email: "Email already exists" };
                }
                return {};
              }),
            });
          }
          return;
        }
        if (
          errorStr ===
          'duplicate key value violates unique constraint "users_email_key"'
        ) {
          if (values.users.length > 1) {
            helpers.setErrors({
              users: [{ email: "One of the users already exists." }],
            });
          } else {
            helpers.setErrors({ users: [{ email: "User already exists." }] });
          }
        } else {
          helpers.setErrors({ users: [{ email: error.response.data.detail }] });
        }
      }
    }
  };

  if (!loaded) return <></>;

  return (
    <Container>
      {!submitted ? (
        <Formik
          validationSchema={InviteFormSchema}
          initialValues={values}
          onSubmit={submit}
        >
          {({ values }) => (
            <StyledForm>
              <HeaderContainer>
                <HeaderText>Invite Users</HeaderText>
                <Button
                  disabled={
                    !values.users.every(
                      (user) => user.email.length !== 0
                    ) /*maybe actually verify as an email*/
                  }
                  type="submit"
                >
                  Send Invite
                </Button>
              </HeaderContainer>
              <FieldArray name="users">
                {({ push, remove }) => (
                  <>
                    {values.users.map((_email, i) => (
                      <InputContainer key={i}>
                        <TextInput
                          name={`users[${i}].firstName`}
                          key={`users[${i}].firstName`}
                          label="First Name"
                          placeholder="First Name"
                        />
                        <TextInput
                          name={`users[${i}].lastName`}
                          key={`users[${i}].lastName`}
                          label="Last Name"
                          placeholder="Last Name"
                        />
                        <TextInput
                          name={`users[${i}].email`}
                          key={`users[${i}].email`}
                          label="Email"
                          placeholder="Enter User email address"
                        />
                        <TextInput
                          name={`users[${i}].role`}
                          key={`users[${i}].role`}
                          label="Role"
                          placeholder="Role"
                          options={Array.from(roles.keys())}
                        />
                        {currentUser.isSuperAdmin ? (
                          <TextInput
                            name={`users[${i}].company`}
                            key={`users[${i}].company`}
                            label="Company"
                            placeholder="Company"
                            options={Array.from(companies.keys())}
                          />
                        ) : null}
                        <TextInput
                          name={`users[${i}].phone`}
                          key={`users[${i}].phone`}
                          label="Phone Number"
                          placeholder=""
                        />
                      </InputContainer>
                    ))}
                    <Hr />
                    <Button
                      outline
                      onClick={() => push({ email: "", role_id: "" })}
                    >
                      Add Another Email
                    </Button>
                  </>
                )}
              </FieldArray>
            </StyledForm>
          )}
        </Formik>
      ) : (
        <div>
          <HeaderContainer>
            <HeaderText>Invites Sent!</HeaderText>
            <Button type="button" onClick={() => history.push("/users")}>
              Back to Users
            </Button>
          </HeaderContainer>
          <PText>
            Users will be sent an invitation email to fill out their profiles
          </PText>
          <br />
          <div>
            {console.log(users?.users)}
            {users?.users.map((user) => (
              <PText key={user.id} style={{ fontStyle: "normal" }}>
                <strong>Email:</strong> {user.email}{" "}
                <Link
                  to={`/users/${user.id}/edit`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Edit User
                </Link>
                <br />
                <strong>Invite Code:</strong> {user.inviteCode} <br />
              </PText>
            ))}
          </div>
        </div>
      )}
    </Container>
  );
};
