import React, { useEffect, useRef, useState } from "react";

import Logo from "../../assets/Navbar/WelbornGarageDoorsLogo.png";
import { NavbarProps } from "./Navbar.types";
import {
  Nav,
  NavLogo,
  NavLinkContainer,
  NavTab,
  IconContainer,
  ProfilePicture,
  PPContainer,
  ProfileText,
} from "./Navbar.styles";
import { Menu, MenuItem } from "@mui/material";

export const Navbar: React.FC<NavbarProps> = ({
  isLoggedIn,
  profilePicture,
  initials,
  logout,
}) => {
  const [openProfileMenu, setOpenProfileMenu] = useState(false);
  const profileRef = useRef<HTMLImageElement>(null);
  const [useInitials, setUseInitials] = useState(false);
  const [avatar, setAvatar] = useState(profilePicture);

  const handleLogout = () => {
    logout();
  };
  useEffect(() => {
    if (
      profilePicture &&
      !(profilePicture.startsWith("http") || profilePicture.startsWith("/"))
    ) {
      setAvatar("/" + profilePicture);
    } else {
      setAvatar(profilePicture);
    }
  }, [profilePicture]);

  return (
    <Nav>
      <NavLogo src={Logo} />
      {isLoggedIn && (
        <>
          <NavLinkContainer>
            <NavTab to="/jobs" activeClassName="selected">
              Jobs
            </NavTab>
            <NavTab to="/users" activeClassName="selected">
              Users
            </NavTab>
            <NavTab to="/doorPricing" activeClassName="selected">
              Door Pricing
            </NavTab>
            <NavTab to="/products" activeClassName="selected">
              Products
            </NavTab>
            <NavTab to="/roles" activeClassName="selected">
              Roles & Permissions
            </NavTab>
            <NavTab to="/companies" activeClassName="selected">
              Companies
            </NavTab>
          </NavLinkContainer>
          <IconContainer>
            <PPContainer
              ref={profileRef}
              onClick={() => {
                setOpenProfileMenu(true);
              }}
            >
              {profilePicture && !useInitials ? (
                <ProfilePicture
                  src={avatar}
                  alt={initials}
                  onError={() => {
                    setUseInitials(true);
                  }}
                />
              ) : (
                <ProfileText>{initials}</ProfileText>
              )}
            </PPContainer>
            <Menu
              id="logout-menu"
              open={openProfileMenu}
              anchorEl={profileRef.current}
              onClose={() => {
                setOpenProfileMenu(false);
              }}
            >
              <MenuItem onClick={handleLogout}>Log out</MenuItem>
            </Menu>
          </IconContainer>
        </>
      )}
    </Nav>
  );
};
