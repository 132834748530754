import {
  Button,
  colorTheme,
  TextInput,
  ToastProps,
  ToastTypes,
} from "@welborne/component-library";
import { Formik, FormikHelpers } from "formik";
import { Header, LoginArea, LoginText } from "./Login.styles";
import { useHistory, useParams } from "react-router-dom";
import * as Yup from "yup";
import { useContext, useEffect, useState } from "react";
import { Api } from "../../../services/api";
import { ToasterContext } from "../../../services/ToasterContext";
import axios from "axios";

export const SignUpFormSchema = Yup.object({
  email: Yup.string().required("Required"),
  password: Yup.string().required("Required"),
  confirmPass: Yup.string().required("Required"),
});

export type SignUpFormType = Yup.InferType<typeof SignUpFormSchema>;

export const SignUp = () => {
  const history = useHistory();
  const { inviteCode } = useParams<{ inviteCode: string }>();
  const { update } = useContext(ToasterContext);
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState("");
  const [values, setValues] = useState<SignUpFormType>({
    email: "",
    password: "",
    confirmPass: "",
  });

  async function getConfirmation(code: string) {
    try {
      const res = await Api.auth.confirm(code);
      setValues((oldValues) => {
        return { ...oldValues, email: res.data.email };
      });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.data?.detail) {
        const errorStr: string = error.response.data.detail;
        console.error(errorStr);
        setError(errorStr);
      }
    }
    setLoaded(true);
  }
  useEffect(() => {
    getConfirmation(inviteCode);
  }, [inviteCode]);

  const handleSignUp = async (
    values: SignUpFormType,
    helpers: FormikHelpers<SignUpFormType>
  ) => {
    if (values.password !== values.confirmPass) {
      helpers.setErrors({ password: "Passwords do not match" });
      return;
    }
    try {
      await Api.auth.signUp(values.email, values.password);
      history.push("/login");
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.data?.detail) {
        console.error(error.response.data.detail);
        const errorStr: string = error.response.data.detail;
        update((oldState: Array<ToastProps>) => {
          oldState.push({
            message: errorStr,
            type: ToastTypes.error,
          });
          return oldState;
        });
        helpers.setErrors({ email: errorStr });
        return;
      }
    }
  };
  if (!loaded) return <></>;

  return (
    <Formik
      initialValues={values}
      validationSchema={SignUpFormSchema}
      onSubmit={handleSignUp}
    >
      <LoginArea>
        <Header>Admin Portal</Header>
        <LoginText>Sign Up</LoginText>
        {error.length > 0 ? (
          <LoginText style={{ color: colorTheme.error }}>
            Error: {error}
          </LoginText>
        ) : (
          <>
            <TextInput name="email" label="Email" disabled />
            <TextInput name="password" type="password" label="Password" />
            <TextInput
              name="confirmPass"
              type="password"
              label="Confirm Password"
            />
            <Button type="submit">Sign Up</Button>
          </>
        )}
      </LoginArea>
    </Formik>
  );
};
