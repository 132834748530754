import Repository from "./Repository";
import {UserDetails} from "./types/users";
const path = "/auth";

//app.get("/auth/confirmation/:code", confirmCode);
//app.post("/auth/signup", signup);
//app.post("/auth/login", login);

const AuthRepository = {
  login(token: string) {
    return Repository.post<any>(`${path}/login`, { accessToken: token });
  },
  signUp(email: string, password: string) {
    return Repository.post<any>(`${path}/signUp`, {
      email: email,
      password: password,
    });
  },
  confirm(code: string) {
    return Repository.get<UserDetails>(`${path}/confirmation/${code}`);
  },
};

export default AuthRepository;
