import Repository from "./Repository";

//app.get("/auth/confirmation/:code", confirmCode);
//app.post("/auth/signup", signup);
//app.post("/auth/login", login);

const ImagesRepository = {
  get(url: string) {
    //prefix the api url if it is missing
    if (!url.startsWith("http")) url = process.env.REACT_APP_API_URL + url;
    return Repository.get<Blob>(`${url}`, { responseType: "blob" });
  },
};

export default ImagesRepository;
