import { colorTheme, textTheme } from "@welborne/component-library";
import styled from "styled-components";

export const Container = styled.div`
  padding: 40px;
`;

export const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const HeaderText = styled.div`
  ${textTheme.h3};
  margin: 0;
  padding: 0;
  text-transform: uppercase;
`;

export const PText = styled.p`
  ${textTheme.h6};
  color: ${colorTheme.grayDark};
  margin: 0;
  padding: 0;
`;
