import { NestedRoutes } from "@welborne/component-library";
import { DoorItemTable } from "./DoorItemTable";

export const DoorItemRoutes: NestedRoutes[] = [
  {
    name: "Door Pricing",
    path: ":companyId/doorPricing",
    component: DoorItemTable,
  },
  {
    name: "Door Pricing",
    path: "doorPricing",
    component: DoorItemTable,
  },
];
