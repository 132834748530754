import {
  Button,
  TextInput,
  ToastProps,
  ToastTypes,
} from "@welborne/component-library";
import { Formik, FormikHelpers } from "formik";
import { ForgotText, Header, LoginArea, LoginText } from "./Login.styles";
import { useHistory } from "react-router-dom";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import axios from "axios";
import { useContext } from "react";
import { ToasterContext } from "../../../services/ToasterContext";

type LoginForm = {
  email: string;
  password: string;
};
export const Login = () => {
  const history = useHistory();
  const auth = getAuth();
  const { update } = useContext(ToasterContext);
  const handleLogin = (
    email: string,
    password: string,
    helpers: FormikHelpers<LoginForm>
  ) => {
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        history.push("/");
      })

      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error(errorCode, errorMessage);
        update((oldState: Array<ToastProps>) => {
          oldState.push({
            message: error.message,
            type: ToastTypes.error,
          });
          return oldState;
        });
        helpers.setErrors({ email: error.message });
        return;
      });
  };
  const values: LoginForm = {
    email: "",
    password: "",
  };

  //TODO fix text input to not require formik
  return (
    <Formik
      initialValues={values}
      onSubmit={(values, helpers) => {
        handleLogin(values.email, values.password, helpers);
      }}
    >
      <LoginArea>
        <Header>Admin Portal</Header>
        <LoginText>Login to your account</LoginText>
        <TextInput name="email" label="Email" />
        <TextInput name="password" type="password" label="Password" />
        <Button type="submit">Login</Button>
        <ForgotText to="/login/forgot">Forgot Password?</ForgotText>
      </LoginArea>
    </Formik>
  );
};
