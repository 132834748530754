import { NestedRoutes } from "@welborne/component-library";
import { CompanyTable } from "./CompanyTable";
import { AddCompany } from "./AddCompany";
import { EditCompany } from "./EditCompany";

export const CompanyRoutes: NestedRoutes = {
  name: "Companies",
  path: "companies",
  component: CompanyTable,
  children: [
    {
      name: "Add Company",
      path: "add",
      component: AddCompany,
    },
    {
      name: "Company",
      path: ":companyId",
      children: [
        {
          name: "Edit Company",
          path: "edit",
          component: EditCompany,
        },
      ],
    },
  ],
};
