import styled, { css } from "styled-components";
import { NavLink } from "react-router-dom";
import { Image } from "../Image";

import { colorTheme, textTheme } from "@welborne/component-library";

export const Nav = styled.nav`
  width: 100vw;
  background: linear-gradient(
    180deg,
    ${colorTheme.primary} 0%,
    ${colorTheme.primaryDark} 100%
  );
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-area: header;
`;

export const NavLogo = styled.img<{ isLoggedIn?: boolean }>`
  padding: 4px 28px;
  width: 180px;
`;

export const NavLinkContainer = styled.div`
  ${textTheme.subtitle2};
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
`;

export const NavTab = styled(NavLink)`
  text-decoration: none;
  white-space: nowrap;
  color: ${colorTheme.white};
  margin: 0px 16px;
  text-transform: uppercase;

  &:hover,
  &.selected {
    color: ${colorTheme.gray};
  }
`;

export const DeadNavTab = styled(NavTab)`
  color: ${colorTheme.white};
  cursor: default;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  padding-right: 39.75px;
`;

export const Icon = styled.img`
  margin-right: 21px;
  &:hover {
    cursor: pointer;
  }
`;

export const PPContainer = styled.div`
  height: 48px;
  width: 48px;
  border-radius: 10px;
  border: 1.5px solid ${colorTheme.white};
  display: flex;
  overflow: hidden;
  &:hover {
    cursor: pointer;
  }
`;

export const ProfilePicture = styled(Image)`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

export const ProfileText = styled.h3`
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  color: ${colorTheme.white};
  ${textTheme.h3};
  line-height: 45px;
  text-align: center;
`;

export const image = css``;
