import { NestedRoutes } from "@welborne/component-library";
import { Forgot } from "./Forgot";
import { Login } from "./Login";
import { SignUp } from "./SignUp";
import { LoginLayout } from "./LoginLayout";

export const LoginRoute: NestedRoutes = {
  name: "Login",
  path: "login",
  component: Login,
  layout: LoginLayout,
  children: [
    {
      name: "Forgot",
      path: "forgot",
      component: Forgot,
    },
  ],
};

export const SignUpRoute: NestedRoutes = {
  name: "SignUp",
  path: "signUp/:inviteCode",
  component: SignUp,
  layout: LoginLayout,
};
