import { NestedRoutes } from "@welborne/component-library";
import { JobTable } from "./JobTable";
import { OverrideJob } from "./OverridePrice";
import { ViewJob } from "./ViewJob";

export const JobRoutes: NestedRoutes = {
  name: "Jobs",
  path: "jobs",
  component: JobTable,
  children: [
    {
      name: "Job",
      path: ":jobId",
      children: [
        {
          name: "Edit",
          path: "edit",
          component: ViewJob,
        },
        {
          name: "Override",
          path: "override",
          component: OverrideJob,
        },
      ],
    },
  ],
};
