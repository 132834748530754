import Repository from "./Repository";
import {
  CreateUserRequest,
  InviteUsers,
  InviteUserRes,
  UserDetails,
} from "./types/users";
const path = "/users";

const UsersRepository = {
  invite(data: InviteUsers) {
    return Repository.post<InviteUserRes>(`${path}/invite`, data);
  },
  list() {
    return Repository.get<{ users: Array<UserDetails> }>(`${path}`);
  },
  create(data: CreateUserRequest) {
    return Repository.post<CreateUserRequest>(`${path}`, data);
  },
  update(id: string, data: Partial<CreateUserRequest>) {
    return Repository.put<CreateUserRequest>(`${path}/${id}`, data);
  },
  get(id: string) {
    return Repository.get<UserDetails>(`${path}/${id}`);
  },
  updatePicture(id: string, photo: File) {
    const form = new FormData();
    form.append("photo", photo);
    return Repository.post(`${path}/${id}/images`, form);
  },
};

export default UsersRepository;
