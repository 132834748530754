import { Table, TableTypes } from "@welborne/component-library";
import { getColumns } from "./CompanyColumn";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { Api } from "../../services/api";
import { Company } from "../../services/api/types/companies";
import { Container } from "../Page.styles";

export const CompanyTable = () => {
  const history = useHistory();
  const [companies, setCompanies] = useState<Array<Company>>([]);
  const [loaded, setLoaded] = useState(false);
  const getCompanies = async () => {
    try {
      const res = await Api.companies.list();
      setCompanies(res.data.companies);
    } catch (error) {
      console.error(error);
    }
    setLoaded(true);
  };
  useEffect(() => {
    getCompanies();
  }, []);
  return (
    <Container>
      {loaded && (
        <Table
          searchable
          title={TableTypes.Companies}
          tableType={TableTypes.Companies}
          userPermissions={[]}
          columns={getColumns("")}
          isLoading={false}
          data={companies}
          handleAddButton={() =>
            history.push((history.location.pathname += "/add"))
          }
        />
      )}
    </Container>
  );
};
