import { AddUser, AddUsersType, Table, TableTypes } from "@welborne/component-library"
import { useEffect, useRef, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import { Api } from "../../services/api"
import { RoleUserDetails } from "../../services/api/types/roles"
import { UserDetails } from "../../services/api/types/users"
import { Container } from "../Page.styles"
import { getColumns } from "../User/UserColumn"

export const AddRoleUser = () => {
  const history = useHistory()
  const [loaded, setLoaded] = useState(false)
  const [roleUsers, setRoleUsers] = useState<Array<RoleUserDetails>>([])
  const [users, setUsers] = useState(new Map<string, UserDetails>())
  const selectedRows = useRef([])
  const { roleId } = useParams<{ roleId: string }>()

  const handleDeleteSelected = () => {
    if (!selectedRows.current.length) return
    console.log(selectedRows.current)
  }

  useEffect(() => {
    async function getRole(roleId: string) {
      if (!roleId) {
        setLoaded(true)
        return
      }
      try {
        const res = await Api.roles.get(roleId)

        setRoleUsers(res.data.users)

        setLoaded(true)
      } catch (error) {
        console.error(error)
        setLoaded(true)
      }
    }

    async function getUsers() {
      try {
        const res = await Api.users.list()

        const usersMap = new Map<string, UserDetails>()
        for (const user of res.data.users) {
          usersMap.set(`${user.firstName} ${user.lastName}`, user)
        }
        setUsers(usersMap)
      } catch (error) {
        console.error(error)
      }
    }

    getUsers()
    getRole(roleId)
  }, [roleId])

  const submit = (values: AddUsersType) => {
    const userIDs = (values.users || []).map(user => users.get(user || "")?.id || "")
    Api.roles
      .updateUsers(roleId, { users: userIDs })
      .then(res => {
        history.push(`/roles/${res.data.id}/edit`)
      })
      .catch(error => {
        console.error(error, values)
      })
  }

  return (
    <Container>
      <AddUser header="Add Users" submitValues={submit} users={Array.from(users.keys())} />
      <Table userPermissions={[]} tableType={TableTypes.RoleUsers} isLoading={!loaded} columns={getColumns(true)} data={roleUsers} selectedRows={selectedRows} handleDeleteSelected={handleDeleteSelected} />
    </Container>
  )
}
