import { ToastProps, ToastTypes } from "@welborne/component-library";
import { useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Api } from "../../services/api";
import {
  CompanyStatus,
  EditCompany as EditCompanyType,
} from "../../services/api/types/companies";
import { ToasterContext } from "../../services/ToasterContext";
import { CompanyForm, CompanyFormTitles, CompanyFormType } from "./CompanyForm";

export const EditCompany = () => {
  const { companyId } = useParams<{ companyId: string }>();
  const { update } = useContext(ToasterContext);
  const history = useHistory();

  const submit = async (values: CompanyFormType) => {
    try {
      const company: EditCompanyType = {
        status: values.status.toLocaleLowerCase() as CompanyStatus,
        name: values.name,
        streetAddress: values.address,
        city: values.city,
        state: values.state,
        zipcode: values.zipcode,
        quoteLifetimeInDays: parseInt(values.quote),
        salesTaxPercentage: parseFloat(values.salesTaxPercentage),
        manufacturerId: values.manufacturerId,
      };
      //if new avatar upload it
      if (values.avatar && typeof values.avatar !== "string") {
        await Api.companies.updatePicture(companyId, values.avatar);
      }
      await Api.companies.update(companyId, company);
      update((oldState: Array<ToastProps>) => {
        oldState.push({
          message: "Company Successfully Saved",
          type: ToastTypes.success,
        });
        return [...oldState];
      });
      history.push("/companies");
    } catch (error) {
      console.error(error, values);
      update((oldState: Array<ToastProps>) => {
        oldState.push({
          message: (error as Error).toString(),
          type: ToastTypes.error,
        });
        return [...oldState];
      });
    }
  };

  return <CompanyForm title={CompanyFormTitles.edit} submit={submit} />;
};
