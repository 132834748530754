import {
  Footer,
  createRoutes,
  NestedRoutes,
  intersect,
} from "@welborne/component-library";
import { Body, Page } from "./App.styles";
import { Route, Redirect, Switch } from "react-router-dom";
import { SignUpRoute, LoginRoute } from "./pages/Auth/Login/LoginRoutes";
import { UserRoutes } from "./pages/User/UserRoutes";
import { CompanyRoutes } from "./pages/Company/CompanyRoutes";
import { ProductRoutes } from "./pages/Product/ProductRoutes";
import { JobRoutes } from "./pages/Job/JobRoutes";
import { RoleRoutes } from "./pages/Role/RoleRoutes";
import { useAuthState } from "react-firebase-hooks/auth";
import { getAuth, signOut } from "firebase/auth";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import Repository from "./services/api/Repository";
import React, { useEffect, useState } from "react";
import { Api } from "./services/api";
import { defaultUser, UserContext } from "./services/UserContext";
import { getUserInitials } from "./pages/User/UserForm";
import { Navbar } from "./services/Navbar";
import { ToasterOven } from "./services/Toaster";
import { ToasterProvider } from "./services/ToasterContext";
import { DoorItemRoutes } from "./pages/DoorItems/DoorItemRoutes";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

// Initialize Firebase
initializeApp(firebaseConfig);

const routes: Array<NestedRoutes> = [
  JobRoutes,
  UserRoutes,
  ...ProductRoutes,
  ...DoorItemRoutes,
  RoleRoutes,
  CompanyRoutes,
];

function LoggedInRedirect(userPermissions: Array<string>) {
  for (const route of routes) {
    if (
      !route.permissions ||
      intersect(route.permissions, userPermissions).length
    ) {
      return <Route render={() => <Redirect to={`/${route.path}`} />} />;
    }
  }
}

function App() {
  const auth = getAuth();
  const [user, loading, error] = useAuthState(auth);
  const logout = () => {
    signOut(auth);
  };
  const [loaded, setLoaded] = useState(false);
  const [currentUser, setCurrentUser] = useState(defaultUser);
  useEffect(() => {
    if (!loading) {
      if (user) {
        Api.auth
          .login(user.accessToken)
          .then((res) => setCurrentUser(res.data));
        Repository.interceptors.request.use(async (config) => {
          config.headers.common[
            "Authorization"
          ] = `Bearer ${await user.getIdToken()}`;
          return config;
        });
      }
      setLoaded(true);
    }
  }, [user, loading, error]);

  const handleRedirects = (user: any) => {
    if (!user) {
      return (
        <Switch>
          {createRoutes(LoginRoute)}
          {createRoutes(SignUpRoute)}
          <Redirect to={"/" + LoginRoute.path} />
        </Switch>
      );
    } else {
      return (
        <Switch>
          {routes.map((route) => createRoutes(route))}
          {LoggedInRedirect([])}
        </Switch>
      );
    }
  };

  return (
    <UserContext.Provider value={currentUser}>
      <ToasterProvider>
        <Page>
          {loaded && (
            <>
              <Navbar
                isLoggedIn={!!user}
                profilePicture={currentUser.avatar}
                initials={getUserInitials(currentUser)}
                logout={logout}
              />
              <Body>{handleRedirects(user)}</Body>
              <Footer />
            </>
          )}
        </Page>
        <ToasterOven />
      </ToasterProvider>
    </UserContext.Provider>
  );
}

export default App;
