import {
  Button,
  DropDown,
  ErrorFocus,
  TextInput,
  ToastProps,
  ToastTypes,
} from "@welborne/component-library";
import { Formik } from "formik";
import { useContext, useEffect, useState } from "react";
import { Api } from "../../services/api";
import { RoleDetails } from "../../services/api/types/roles";
import { useHistory, useParams } from "react-router-dom";
import {
  ButtonContainer,
  FormContainer,
  HeaderContainer,
  HeaderText,
  Horizontal,
  PText,
  StyledForm,
} from "./InviteUsers.styles";
import { CreateUserRequest, UserDetails } from "../../services/api/types/users";
import { Container } from "../Page.styles";
import { AvatarUpload } from "../../services/AvatarUpload";
import * as Yup from "yup";
import { ToasterContext } from "../../services/ToasterContext";

export const UserFormSchema = Yup.object({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  role: Yup.mixed(),
  email: Yup.string().required("Required"),
  phone: Yup.string().required("Required"),
  avatar: Yup.mixed(),
});

export type UserFormType = Yup.InferType<typeof UserFormSchema>;

export interface HasName {
  firstName: string;
  lastName: string;
}

export function getUserInitials(user: HasName) {
  return (user.firstName || " ")[0] + (user.lastName || " ")[0];
}

export const UserForm = () => {
  const [roles, setRoles] = useState<Array<RoleDetails>>([]);
  const getRoles = async () => {
    try {
      const res = await Api.roles.list();
      setRoles(res.data.roles);
    } catch (error) {
      console.error(error);
    }
    setLoaded(true);
  };

  useEffect(() => {
    getRoles();
  }, []);

  let userRoles = roles.map((role) => ({ id: role.id, name: role.name }));

  const history = useHistory();
  const [loaded, setLoaded] = useState(false);
  const [values, setValues] = useState<UserFormType>({
    firstName: "",
    lastName: "",
    role: "",
    email: "",
    phone: "",
    avatar: "",
  });
  const { userId } = useParams<{ userId: string }>();
  const { update } = useContext(ToasterContext);

  const [user, setUser] = useState<UserDetails>();

  async function getUser(userId: string) {
    if (!userId) {
      console.error("No user Id");
      return;
    }
    try {
      const res = await Api.users.get(userId);
      setUser(res.data);
      setValues({
        firstName: res.data.firstName,
        lastName: res.data.lastName,
        role: res.data.role || "",
        email: res.data.email,
        phone: res.data.phone || "",
        avatar: res.data.avatar || "",
      });
      setLoaded(true);
    } catch (error) {
      console.error(error);
      setLoaded(true);
    }
  }
  useEffect(() => {
    getUser(userId);
  }, [userId]);

  function returnToGrid() {
    history.push("/users");
  }

  async function submit(values: UserFormType) {
    const updateUser: CreateUserRequest = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      phone: values.phone,
      roleId: values.role.id,
    };
    if (typeof values.avatar !== "string") {
      await Api.users.updatePicture(userId, values.avatar);
    }
    Api.users
      .update(userId, updateUser)
      .then(() => {
        update((oldState: Array<ToastProps>) => {
          oldState.push({
            message: "User Successfully Saved",
            type: ToastTypes.success,
          });
          return [...oldState];
        });
        returnToGrid();
      })
      .catch((error) => {
        console.error(values, error);
        update((oldState: Array<ToastProps>) => {
          oldState.push({
            message: error.toString(),
            type: ToastTypes.error,
          });
          return oldState;
        });
      });
  }

  return (
    <Container>
      {loaded && (
        <Formik
          initialValues={values}
          validationSchema={UserFormSchema}
          onSubmit={submit}
        >
          {({ setFieldValue, values }) => (
            <StyledForm>
              <ErrorFocus />
              <HeaderContainer>
                <ButtonContainer>
                  <HeaderText>{`${values.firstName} ${values.lastName}`}</HeaderText>
                  <PText>{user?.company?.name}</PText>
                </ButtonContainer>
                <ButtonContainer>
                  <Button type="submit">Save</Button>
                  <Button type="button" outline onClick={() => returnToGrid()}>
                    Cancel
                  </Button>
                </ButtonContainer>
              </HeaderContainer>
              <Horizontal>
                <AvatarUpload
                  name="avatar"
                  setFieldValue={setFieldValue}
                  placeholder={getUserInitials(values)}
                />
                <FormContainer style={{ width: "436px" }}>
                  <TextInput
                    name="firstName"
                    key="firstName"
                    label="First Name"
                    placeholder="First Name"
                  />
                  <TextInput
                    name="lastName"
                    key="lastName"
                    label="Last Name"
                    placeholder="Last Name"
                  />
                  <DropDown
                    label="Role"
                    className="noShadow"
                    width={100}
                    selected="role.name"
                    name="role.name"
                    key="role.id"
                    setFieldValue={(_name, item) => setFieldValue("role", item)}
                    data={userRoles}
                  />
                  <TextInput
                    name="email"
                    key="email"
                    label="Email"
                    placeholder="Email"
                  />
                  <TextInput
                    name="phone"
                    key="phone"
                    label="Primary Phone"
                    placeholder="Primary Phone"
                  />
                </FormContainer>
              </Horizontal>
            </StyledForm>
          )}
        </Formik>
      )}
    </Container>
  );
};
