import styled from "styled-components";
import { colorTheme, textTheme } from "@welborne/component-library";
import { Link } from "react-router-dom";
import LoginImage from "../../../assets/Login/login.png";
import { Form } from "formik";

export const Main = styled.div`
  width: 100vw;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: "main" "image";
`;

export const LoginContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const LoginArea = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-self: stretch;
  justify-self: end;
  box-sizing: border-box;
  max-width: 720px;
  width: 442px;
  padding: 62px 34px;
`;

export const Header = styled.div`
  color: ${colorTheme.black};
  ${textTheme.h3};
`;

export const LoginText = styled.p`
  margin: 0;
  padding: 0;
  ${textTheme.body1};
`;

export const ForgotText = styled(Link)`
  margin: 0;
  padding: 0;
  ${textTheme.body2};
  font-weight: bold;
  color: ${colorTheme.primary};
`;

export const LoginSide = styled.div`
  background-image: url("${LoginImage}");
  background-color: ${colorTheme.primary};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-blend-mode: soft-light;
  align-self: stretch;
`;
