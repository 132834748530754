export enum RoleStatus {
  None = 0,
  Active,
  Inactive,
}

export interface RoleDetails {
  id: string;
  name: string;
  description: string;
  status: RoleStatus;
  permissions: RolePermissionDetails[];
  users: RoleUserDetails[];
  createdAt: Date;
  updatedAt: Date;
}

export interface RolePermissionDetails {
  id: string;
  groupName: string;
  name: string;
  hasPermission: boolean;
  createdAt: Date;
  updatedAt: Date;
}

export interface RoleUserDetails {
  id: string;
  firstName: string;
  lastName: string;
  fullName: string;
}

export interface UpdateRole {
  name: string;
  description: string;
  status: string;
  permissions: UpdateRolePermission[];
}

export interface UpdateRolePermission {
  permissionId: string;
  hasPermission: boolean;
}

export interface UpdateUsersRole {
  users: string[];
}
