import { useHistory } from "react-router-dom";
import { Api } from "../../services/api";
import { CompanyForm, CompanyFormTitles, CompanyFormType } from "./CompanyForm";
import { EditCompany as EditCompanyType } from "../../services/api/types/companies";
import { useContext } from "react";
import { ToasterContext } from "../../services/ToasterContext";
import { ToastProps, ToastTypes } from "@welborne/component-library";

export const AddCompany = () => {
  const history = useHistory();
  const { update } = useContext(ToasterContext);

  const submit = async (values: CompanyFormType) => {
    const company: Omit<EditCompanyType, "status"> = {
      name: values.name,
      streetAddress: values.address,
      city: values.city,
      state: values.state,
      zipcode: values.zipcode,
      quoteLifetimeInDays: parseInt(values.quote),
      salesTaxPercentage: parseFloat(values.salesTaxPercentage),
      manufacturerId: values.manufacturerId,
    };
    let id = "";
    try {
      //create the company
      const res = await Api.companies.create(company);
      id = res.data.id;
      //if there is an avatar upload it
      if (values.avatar && typeof values.avatar !== "string") {
        await Api.companies.updatePicture(id, values.avatar);
      }
    } catch (error) {
      console.error(error, values);
      update((oldState: Array<ToastProps>) => {
        oldState.push({
          message: (error as Error).toString(),
          type: ToastTypes.error,
        });
        return [...oldState];
      });
    }
    //if company got created move to edit
    if (id.length !== 0) {
      update((oldState: Array<ToastProps>) => {
        oldState.push({
          message: "Company Successfully Created",
          type: ToastTypes.success,
        });
        return [...oldState];
      });
      history.push(`/companies/${id}/edit`);
    }
  };

  return <CompanyForm title={CompanyFormTitles.add} submit={submit} />;
};
