import {
  cellStyle,
  compactCellStyle,
  compactHeaderStyle,
} from "@welborne/component-library";
import { DoorItem } from "../../services/api/types/products";
import { roundToTwo } from "../../services/formatNumber";

interface ColumnType<T> {
  field: keyof T;
  [index: string]: any;
}

// define your columns
export const getColumns = (token: string): Array<ColumnType<DoorItem>> => {
  return [
    {
      title: "Number",
      field: "product_number",
      cellStyle: compactCellStyle,
      headerStyle: compactHeaderStyle,
    },
    {
      title: "Name",
      field: "name",
      cellStyle: cellStyle,
    },
    {
      title: "Color Category",
      field: "color_product_category",
      cellStyle: compactCellStyle,
      headerStyle: compactHeaderStyle,
    },
    {
      title: "Insulation Category",
      field: "insulation_product_category",
      cellStyle: compactCellStyle,
      headerStyle: compactHeaderStyle,
    },
    {
      title: "Door Name",
      field: "door_name",
      cellStyle: compactCellStyle,
      headerStyle: compactHeaderStyle,
    },
    {
      title: "Door Price",
      field: "door_price",
      cellStyle: compactCellStyle,
      headerStyle: compactHeaderStyle,
      searchable: false,
      render: (doorItem: DoorItem) => {
        return "$" + roundToTwo(doorItem.door_price || 0);
      },
    },
    {
      title: "Window Plain Price",
      field: "window_plain_price",
      cellStyle: compactCellStyle,
      headerStyle: compactHeaderStyle,
      searchable: false,
      render: (doorItem: DoorItem) => {
        return "$" + roundToTwo(doorItem.window_plain_price || 0);
      },
    },
    {
      title: "Window Frosted Price",
      field: "window_frosted_tinted_price",
      cellStyle: compactCellStyle,
      headerStyle: compactHeaderStyle,
      searchable: false,
      render: (doorItem: DoorItem) => {
        return "$" + roundToTwo(doorItem.window_frosted_tinted_price || 0);
      },
    },
    {
      title: "Panel Size",
      field: "panel_size",
      cellStyle: compactCellStyle,
      headerStyle: compactHeaderStyle,
    },
    {
      title: "Door Size",
      field: "width",
      cellStyle: compactCellStyle,
      headerStyle: compactHeaderStyle,
      searchable: false,
      render: (doorItem: DoorItem) => {
        return `${doorItem.width}X${doorItem.height}`;
      },
    },
    {
      title: "Date Created",
      field: "created_at",
      searchable: false,
      align: "left",
      cellStyle: compactCellStyle,
      headerStyle: compactHeaderStyle,
      render: (doorItem: DoorItem) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            {new Date(doorItem.created_at).toLocaleDateString("en-us")}
          </div>
        );
      },
    },
  ];
};
