import { useHistory } from "react-router-dom";
import { Api } from "../../services/api";
import { ProductForm, ProductFormTitles, ProductFormType } from "./ProductForm";
import { ProductAdd } from "../../services/api/types/products";
import { useContext, useEffect, useState } from "react";
import { ToastProps, ToastTypes } from "@welborne/component-library";
import { ToasterContext } from "../../services/ToasterContext";

export const AddProduct = () => {
  const history = useHistory();
  const [companyId, setCompanyId] = useState("");
  const { update } = useContext(ToasterContext);

  const getCompanyId = async () => {
    const companies = (await Api.companies.list()).data.companies;
    if (companies.length) {
      setCompanyId(companies[0].id);
    }
  };
  useEffect(() => {
    getCompanyId();
  }, []);

  const submit = (values: ProductFormType) => {
    const product: ProductAdd = {
      name: values.name,
      companyId: companyId,
      description: values.description || "",
      basePrice: values.price,
      productCategoryId: values.productCategoryId,
    };
    Api.products
      .create(product)
      .then((res) => {
        update((oldState: Array<ToastProps>) => {
          oldState.push({
            message: "Product Successfully Created",
            type: ToastTypes.success,
          });
          return [...oldState];
        });
        history.push(`/products/${res.data.id}/edit`);
      })
      .catch((error) => {
        console.error(error, values);
        update((oldState: Array<ToastProps>) => {
          oldState.push({
            message: error.toString(),
            type: ToastTypes.error,
          });
          return [...oldState];
        });
      });
  };

  return <ProductForm title={ProductFormTitles.add} submit={submit} />;
};
