import Repository from "./Repository";
import {
  BulkProductUpdate,
  DoorItem,
  Product,
  ProductAdd,
  ProductEdit,
} from "./types/products";
const path = "/products";

const ProductsRepository = {
  list(companyId: string) {
    return Repository.get<{ products: Array<Product> }>(
      `${path}/company/${companyId}`
    );
  },
  listDoorItems(companyId: string) {
    return Repository.get<{ doorPermutations: Array<DoorItem> }>(
      `${path}/doors/company/${companyId}`
    );
  },
  create(product: ProductAdd) {
    return Repository.post<Product>(`${path}`, product);
  },
  update(id: string, product: ProductEdit) {
    return Repository.put<Product>(`${path}/${id}`, product);
  },
  get(id: string, companyId: string) {
    return Repository.get<Product>(`${path}/${id}/company/${companyId}`);
  },
  bulkUpdate(updates: BulkProductUpdate) {
    return Repository.put<Product>(`${path}/bulk-update`, updates);
  },
  uploadCsv(companyId: string, csv: File) {
    const form = new FormData();
    form.append("csv-file", csv);
    return Repository.post<any>(
      `${path}/company/${companyId}/pricing-csv-upload`,
      form
    );
  },
};

export default ProductsRepository;
