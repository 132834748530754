import { Button, TextInput } from "@welborne/component-library";
import { Formik, FormikHelpers } from "formik";
import { useState } from "react";
import { Header, LoginArea, LoginText } from "./Login.styles";
import { useHistory } from "react-router-dom";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";

export const Forgot = () => {
  const [sent, setSent] = useState(false);
  const history = useHistory();
  const auth = getAuth();

  const values = {
    email: "",
  };

  const handleForgot = async (
    email: string,
    helpers: FormikHelpers<{ email: string }>
  ) => {
    try {
      await sendPasswordResetEmail(auth, email);
      setSent(true);
    } catch (error: any) {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.error(errorCode, errorMessage);
      helpers.setErrors({ email: errorMessage });
    }
  };

  //TODO fix text input to not require formik
  return (
    <Formik
      initialValues={values}
      onSubmit={(values, helpers) => {
        handleForgot(values.email, helpers);
      }}
    >
      {!sent ? (
        <LoginArea>
          <Header>Admin Portal</Header>
          <LoginText>
            Forgot your password? Please enter your email address below.
          </LoginText>
          <TextInput name="email" label="Email" />
          <Button type="submit">Send</Button>
        </LoginArea>
      ) : (
        <LoginArea>
          <Header>Email Sent!</Header>
          <LoginText>We've sent you an email to reset your password.</LoginText>
          <Button type="button" onClick={() => history.push("/")}>
            Return to Login
          </Button>
        </LoginArea>
      )}
    </Formik>
  );
};
