import React, { useState } from "react";
import { AvatarUploadProps } from "./AvatarUpload.types";
import {
  ButtonContainer,
  Container,
  Image,
  ImageContainer,
  PlaceHolderText,
} from "./AvatarUpload.styles";
import { useField } from "formik";
import {
  FileUpload,
  colorTheme,
  CenterPopup,
} from "@welborne/component-library";
import EditUpload from "../../assets/EditAvatar";

export const AvatarUpload: React.FC<AvatarUploadProps> = ({
  setFieldValue,
  placeholder,
  disabled,
  ...props
}) => {
  const [field] = useField(props); // add meta for error handling
  const [editOpen, setEditOpen] = useState(false);

  const placeFieldValue = (
    name: string,
    value: Map<string, File>,
    shouldValidate = true
  ) => {
    //extract first value from map
    const file = value.values().next().value;
    if (setFieldValue) setFieldValue(name, file, shouldValidate);
    //hide the file upload
    setEditOpen(false);
  };

  return (
    <Container>
      <ImageContainer hasImg={!!field.value}>
        {!!field.value ? (
          typeof field.value === "string" ? (
            <Image src={field.value} />
          ) : (
            <Image src={URL.createObjectURL(field.value)} />
          )
        ) : (
          <PlaceHolderText>{placeholder}</PlaceHolderText>
        )}
        {!disabled ? (
          <ButtonContainer
            type="button"
            onClick={() => {
              setEditOpen((oldOpen) => !oldOpen);
            }}
          >
            <EditUpload color={colorTheme.primary} width="35px" height="35px" />
          </ButtonContainer>
        ) : null}
      </ImageContainer>
      {editOpen && (
        <CenterPopup setOpen={setEditOpen}>
          <FileUpload
            {...props}
            maxNumberOfFiles={1}
            allowedFileTypes={"image/*"}
            setFieldValue={placeFieldValue}
          />
        </CenterPopup>
      )}
    </Container>
  );
};
