import {
  Button,
  ErrorFocus,
  RadioList,
  TextInput,
} from "@welborne/component-library";
import { Formik, Form } from "formik";
import { History } from "history";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { Api } from "../../services/api";
import { useHistory, useParams } from "react-router-dom";
import {
  ButtonContainer,
  FormContainer,
  StyledForm,
} from "./ProductForm.styles";
import { Container, HeaderContainer, HeaderText } from "../Page.styles";
import { ProductStatus } from "../../services/api/types/products";
import { capitalize } from "../../services/capitalize";
import { UserContext } from "../../services/UserContext";
import { AvatarUpload } from "../../services/AvatarUpload";
import * as Yup from "yup";
import { User } from "../../services/UserContext";
import { setCompanyId } from "./ProductTable";
import { roundToTwo } from "../../services/formatNumber";

export const ProductFormSchema = Yup.object({
  name: Yup.string().required("Required"),
  status: Yup.string().required("Required"),
  image: Yup.string().required("Required"),
  description: Yup.string(),
  price: Yup.string(),
  productCategoryId: Yup.string().required("Required"),
  companyId: Yup.string().required("Required"),
});

export function returnToGrid(history: History) {
  const pathArr = history.location.pathname.slice(1).split("/");
  pathArr.splice(pathArr.indexOf("products") + 1);
  const location = "/" + pathArr.join("/");
  history.push(location);
}

export type ProductFormType = Yup.InferType<typeof ProductFormSchema>;

export enum ProductFormTitles {
  add = "Add Product",
  edit = "Edit Product",
}

// set of door categories for which are not allowed to be edited
const doorCategories = new Set([
  "Timeless Collection",
  "Contemporary Collection",
]);

export const ProductForm: React.FC<{
  title: ProductFormTitles;
  submit: (values: ProductFormType) => void;
}> = ({ title, submit }) => {
  const currentUser: User = useContext(UserContext);
  const history = useHistory();
  const [loaded, setLoaded] = useState(false);
  const [values, setValues] = useState<ProductFormType>({
    status: capitalize(ProductStatus.active),
    image: "",
    name: "",
    description: "",
    price: "",
    productCategoryId: "",
    companyId: "",
  });
  const [productCategories, setProductCategories] = useState(
    new Map<string, string>()
  );

  const { companyId, productId } =
    useParams<{ companyId: string; productId: string }>();

  const [companies, setCompanies] = useState<Map<string, string>>(new Map());

  async function getCompanies() {
    if (!currentUser.isSuperAdmin) {
      setValues((values) => {
        return { ...values, companyId: currentUser.company.id };
      });
      return;
    }
    const res = await Api.companies.list();
    const companies = new Map<string, string>();
    for (const company of res.data.companies) {
      companies.set(company.name, company.id);
      if (companyId === company.id)
        setValues((values) => {
          return { ...values, companyId: company.name };
        });
    }
    setCompanies(companies);
  }
  useEffect(() => {
    getCompanies();
    if (!companyId) setCompanyId(history, companyId, currentUser.company.id);
  }, [currentUser, companyId]);

  useEffect(() => {
    setLoaded(false);
    async function getCategories() {
      const res = await Api.productCategories.list();
      const categories = new Map<string, string>();
      const categoriesByID = new Map<string, string>();
      for (const category of res.data.productCategories) {
        categories.set(category.name, category.id);
        categoriesByID.set(category.id, category.name);
      }
      setProductCategories(categories);
      return categoriesByID;
    }

    async function getProduct(productId: string) {
      const categories = await getCategories();
      if (!productId) {
        setLoaded(true);
        return;
      }
      if (!companyId) return;
      try {
        const res = await Api.products.get(productId, companyId);
        setValues((values) => {
          return {
            ...values,
            companyId: currentUser.isSuperAdmin
              ? values.companyId
              : currentUser.company.id,
            status: capitalize(ProductStatus[res.data.status]),
            image: res.data.productIconLink,
            description: res.data.description,
            name: res.data.name,
            price: res.data.productCostVariants?.[0]
              ? "$" + roundToTwo(res.data.productCostVariants[0].cost)
              : "",
            productCategoryId: categories.get(res.data.productCategoryId) || "",
          };
        });
      } catch (error) {
        console.error(error);
      }
      setLoaded(true);
    }

    if (currentUser.company.id !== "") getProduct(productId);
  }, [productId, currentUser, companyId]);

  function preSubmit(oldValues: ProductFormType) {
    const values = { ...oldValues };
    values.price = values.price?.replaceAll("$", "");
    values.productCategoryId =
      productCategories.get(values.productCategoryId) || "";
    values.companyId = currentUser.isSuperAdmin
      ? companies.get(values.companyId) || ""
      : values.companyId;
    submit(values);
  }

  return (
    <Container>
      {loaded && (companies.size > 0 || !currentUser.isSuperAdmin) ? (
        <Formik
          initialValues={values}
          validationSchema={ProductFormSchema}
          onSubmit={preSubmit}
        >
          {({ values, setFieldValue }) => (
            <StyledForm>
              <ErrorFocus />
              <HeaderContainer>
                <HeaderText>{title}</HeaderText>
                <ButtonContainer>
                  {currentUser.isSuperAdmin ? (
                    <TextInput
                      setFieldValue={(field: string, value: any) => {
                        setFieldValue(field, value);
                        setCompanyId(
                          history,
                          companyId,
                          companies.get(value) || companyId
                        );
                      }}
                      name={`companyId`}
                      key={`companyId`}
                      placeholder="Company"
                      options={Array.from(companies.keys())}
                    />
                  ) : null}
                  <Button type="submit">Save</Button>
                  <Button
                    type="button"
                    outline
                    onClick={() => returnToGrid(history)}
                  >
                    Cancel
                  </Button>
                </ButtonContainer>
              </HeaderContainer>
              <AvatarUpload
                name="image"
                setFieldValue={setFieldValue}
                disabled
              />
              <FormContainer style={{ width: "436px" }}>
                {productId && (
                  <RadioList
                    name="status"
                    label="Status"
                    options={Object.keys(ProductStatus).map((stat) =>
                      capitalize(stat)
                    )}
                    disabled
                  />
                )}
                <TextInput name="name" label="Product Name" />
                <TextInput name="description" label="Description" />
                <TextInput
                  name="price"
                  label="Base Price"
                  type="cost"
                  min="0"
                  step="0.01"
                  placeholder="N/A"
                  disabled={doorCategories.has(values.productCategoryId)}
                />
                <TextInput
                  name="productCategoryId"
                  label="Category"
                  options={Array.from(productCategories.keys())}
                  disabled
                />
              </FormContainer>
            </StyledForm>
          )}
        </Formik>
      ) : null}
    </Container>
  );
};
