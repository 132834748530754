import { Table, TableTypes } from "@welborne/component-library";
import { getColumns } from "./UserColumn";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { Api } from "../../services/api";
import { UserDetails } from "../../services/api/types/users";
import { Container } from "../Page.styles";

export const UserTable = () => {
  const history = useHistory();
  const [loaded, setLoaded] = useState(false);
  const [users, setUsers] = useState<Array<UserDetails>>([]);
  const getUsers = async () => {
    try {
      const res = await Api.users.list();
      setUsers(res.data.users);
    } catch (error) {
      console.error(error);
    }
    setLoaded(true);
  };
  useEffect(() => {
    getUsers();
  }, []);
  return (
    <Container>
      {loaded && (
        <Table
          searchable
          title={TableTypes.Users}
          tableType={TableTypes.Users}
          userPermissions={[]}
          columns={getColumns()}
          isLoading={!loaded}
          data={users}
          handleAddButton={() =>
            history.push((history.location.pathname += "/inviteUsers"))
          }
        />
      )}
    </Container>
  );
};
