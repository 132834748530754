import { Company } from "./companies";

export enum ProductStatus {
  active = "active",
  inactive = "inactive",
}

export type Product = {
  id: string;
  productIconLink: string;
  iconUrl: string;
  status: ProductStatus;
  name: string;
  description: string;
  basePrice?: string;
  price?: number;
  productCategoryId: string;
  productCategoryName: string;
  companyId: string;
  company: Partial<Company>;
  createdAt: string | Date;
  costVariants?: [{ price: number; companyId: string }];
  productCostVariants?: [
    { cost: number; name: string; width: number; height: number }
  ];
};

export type DoorItem = {
  id: string;
  name: string;
  color_product_category: string;
  insulation_product_category: string;
  product_number: string;
  window_plain_price: number;
  window_frosted_tinted_price: number;
  panel_size: string;
  door_name: string;
  door_price: number;
  width: number;
  height: number;
  created_at: string | Date;
};

export interface ProductCategory {
  id: string;
  name: string;
  status: string;
  categoryType: string;
  productCategory: string;
  createdAt: Date;
  updatedAt: Date;
}

export type ProductCategoryAdd = Omit<ProductCategory, "id" | "createdAt">;

//productNumber: string; // remove after adding prod num generator
//description: string;
//cost: number;
//productCategoryId: string;
//companyId: string;
export type ProductAdd = Omit<
  Product,
  | "id"
  | "productIconLink"
  | "iconUrl"
  | "status"
  | "productCategoryName"
  | "company"
  | "createdAt"
  | "costVariants"
>;

//description: string;
//cost: number;
//productCategoryId: string;
//status: ProductStatus;
export type ProductEdit = Omit<
  Product,
  | "id"
  | "productIconLink"
  | "iconUrl"
  | "productNumber"
  | "productCategoryName"
  | "productCategoryId"
  | "company"
  | "createdAt"
  | "basePrice"
  | "costVariants"
>;

export interface BulkProductUpdate {
  productUids: string[];
  productCategoryId: string;
  status: ProductStatus;
  exactCost?: number;
  increase: boolean;
  percentage: boolean;
  value: number;
}
