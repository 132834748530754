import * as React from "react";

function SvgEditAvatar(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={17.5} cy={17.5} r={17.5} fill="currentColor" />
      <path
        d="M12 21.5V24h2.5l7.373-7.373-2.5-2.5L12 21.5zm11.807-6.807c.26-.26.26-.68 0-.94l-1.56-1.56a.664.664 0 00-.94 0l-1.22 1.22 2.5 2.5 1.22-1.22z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgEditAvatar;
