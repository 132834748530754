import { getAuth } from "firebase/auth";
import { HTMLProps, ReactElement, useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Api } from "./api";

export const Image: React.FC<HTMLProps<HTMLImageElement>> = ({
  crossOrigin,
  src,
  ...props
}): ReactElement | null => {
  const [loaded, setLoaded] = useState(false);
  const [token, setToken] = useState("");
  const [image, setImage] = useState("");
  const [user] = useAuthState(getAuth());
  const getImage = async () => {
    if (src) {
      //handle if it is a freshly uploaded local image
      if (src.startsWith("blob:")) {
        setImage(src);
        setLoaded(true);
        return;
      }
      try {
        const res = await Api.images.get(src);
        if (res.data instanceof Blob) {
          setImage(URL.createObjectURL(res.data));
        }
      } catch (error) {
        console.error("No image: ", src);
      }
      setLoaded(true);
    }
  };
  useEffect(() => {
    getImage();
  }, [user, src]);

  if (loaded) {
    return <img {...props} src={image} />;
  }
  return null;
};
