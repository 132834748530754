import Repository from "./Repository"
import { CreateJob, GetJob, Job, OverrideItemProductPrice, UpdateJob } from "./types/jobs"
const path = "/projects"

const JobsRepository = {
  //TODO setup url params for all or just company projects
  list() {
    return Repository.get<{ entries: Array<Job> }>(`${path}?inactive=true&expired=true&companyProjects=true`)
  },
  create(data: CreateJob) {
    return Repository.post<Job>(`${path}`, data)
  },
  update(id: string, data: UpdateJob) {
    return Repository.put<Job>(`${path}/${id}`, data)
  },
  get(id: string) {
    return Repository.get<GetJob>(`${path}/${id}`)
  },
  overrideProductPrice(jobId: string, itemId: string, override: OverrideItemProductPrice) {
    return Repository.put<GetJob>(`${path}/${jobId}/items/${itemId}/products/price-override`, override)
  },
  overrideTotalPrice(jobId: string, totalPrice: number) {
    return Repository.post(`${path}/${jobId}/overrideTotalPrice`, { totalPrice: totalPrice })
  }
}

export default JobsRepository
