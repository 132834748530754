import { Table, TableTypes, ToastProps, ToastTypes } from "@welborne/component-library"
import { getColumns } from "./RoleColumn"
import { useHistory } from "react-router-dom"
import { useContext, useEffect, useRef, useState } from "react"
import { Api } from "../../services/api"
import { RoleDetails } from "../../services/api/types/roles"
import { Container } from "../Page.styles"
import axios from "axios"
import { ToasterContext } from "../../services/ToasterContext"

export const RoleTable = () => {
  const history = useHistory()
  const { update } = useContext(ToasterContext)
  const [loaded, setLoaded] = useState(false)
  const [roles, setRoles] = useState<Array<RoleDetails>>([])
  const selectedRows = useRef<Array<RoleDetails>>([])

  const getRoles = async () => {
    try {
      const res = await Api.roles.list()
      setRoles(res.data.roles)
    } catch (error) {
      console.error(error)
    }
    setLoaded(true)
  }

  useEffect(() => {
    getRoles()
  }, [])

  const deleteRoles = async (roleIds: Array<string>) => {
    try {
      //delete roles
      await Api.roles.delete(roleIds)
      //send message
      update((oldState: Array<ToastProps>) => {
        oldState.push({
          message: "Role Successfully Deleted",
          type: ToastTypes.success
        })
        return [...oldState]
      })
      //refresh roles
      getRoles()
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.data?.detail) {
        const errorStr: string = error.response.data.detail
        console.log(errorStr)
        update((oldState: Array<ToastProps>) => {
          oldState.push({
            message: errorStr,
            type: ToastTypes.error
          })
          return [...oldState]
        })
      } else {
        update((oldState: Array<ToastProps>) => {
          oldState.push({
            message: "Failed to delete Roles.",
            type: ToastTypes.error
          })
          return [...oldState]
        })
      }
    }
  }

  const handleDeleteSelected = () => {
    if (!selectedRows.current.length) return
    deleteRoles(selectedRows.current.map(role => role.id))
  }

  return <Container>{loaded && <Table searchable editable title={TableTypes.Roles} tableType={TableTypes.Roles} userPermissions={[]} columns={getColumns("")} isLoading={!loaded} data={roles} selectedRows={selectedRows} handleAddButton={() => history.push((history.location.pathname += "/add"))} handleDeleteSelected={handleDeleteSelected} handleDelete={(id: string) => deleteRoles([id])} />}</Container>
}
