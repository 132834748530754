import {
  colorTheme,
  textTheme,
  ThirtyFiveHex,
} from "@welborne/component-library";
import styled from "styled-components";
import { FormContainer } from "./ProductForm.styles";

export const BodyContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100%;
`;

export const ProductGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 10px;
`;

export const ProductList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;

  padding: 20px;
  border-right: 5px solid ${colorTheme.grayDark}${ThirtyFiveHex};
`;

export const ProductContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;

  &:not(:last-child) {
    border-bottom: 1px solid ${colorTheme.grayLight};
    padding-bottom: 20px;
  }
`;

export const ProductHead = styled.h4`
  ${textTheme.button};
  color: ${colorTheme.grayDark};
  margin: 0;
  padding: 0;
`;
export const ProductItem = styled.p`
  ${textTheme.body1};
  color: ${colorTheme.grayDark};
  margin: 0;
  padding: 0;
`;

export const UpdateContainer = styled(FormContainer)`
  padding: 0px 75px;
  height: min-content;
`;

export const ChangeContainer = styled.div`
  padding: 20px;
  border: 1px solid ${colorTheme.grayLight};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
