import { ToastProps, ToastTypes } from "@welborne/component-library";
import { useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Api } from "../../services/api";
import { ProductEdit, ProductStatus } from "../../services/api/types/products";
import { ToasterContext } from "../../services/ToasterContext";
import { returnToGrid } from "./ProductForm";
import { ProductForm, ProductFormTitles, ProductFormType } from "./ProductForm";

export const EditProduct = () => {
  const { productId } = useParams<{ productId: string }>();
  const history = useHistory();
  const { update } = useContext(ToasterContext);

  const submit = (values: ProductFormType) => {
    const product: ProductEdit = {
      status: values.status.toLocaleLowerCase() as ProductStatus,
      name: values.name,
      description: values.description || "",
      companyId: values.companyId,
      price: values.price ? parseFloat(values.price) : undefined,
    };
    Api.products
      .update(productId, product)
      .then((res) => {
        update((oldState: Array<ToastProps>) => {
          oldState.push({
            message: "Product Successfully Saved",
            type: ToastTypes.success,
          });
          return [...oldState];
        });
        returnToGrid(history);
      })
      .catch((error) => {
        console.error(error, values);
        update((oldState: Array<ToastProps>) => {
          oldState.push({
            message: error.toString(),
            type: ToastTypes.error,
          });
          return [...oldState];
        });
      });
  };

  return <ProductForm title={ProductFormTitles.edit} submit={submit} />;
};
