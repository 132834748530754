import { colorTheme, textTheme } from "@welborne/component-library";
import { Form } from "formik";
import styled from "styled-components";

export const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const HeaderText = styled.div`
  ${textTheme.h3};
  margin: 0;
  padding: 0;
  text-transform: uppercase;
`;

export const Hr = styled.hr`
  margin: 10px 0;
  border-top: 1px solid ${colorTheme.grayLight};
  width: 100%;
`;

export const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const Horizontal = styled.div`
  display: flex;
  gap: 80px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: 20px;
`;

export const InputContainer = styled.div`
  display: flex;
  gap: 20px;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const PText = styled.p`
  ${textTheme.h6};
  color: ${colorTheme.grayDark};
  margin: 0;
  padding: 0;
`;
