import styled from "styled-components";
import { colorTheme, textTheme } from "@welborne/component-library";

export const UploadContainer = styled.div`
  padding: 25px 35px;
  background-color: ${colorTheme.white};
  width: 550px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
export const UploadText = styled.p`
  ${textTheme.subtitle2};
  text-align: center;
  margin: 0;
  padding: 0;
`;
