import { cellStyle } from "@welborne/component-library";
import { RoleDetails, RoleStatus } from "../../services/api/types/roles";

interface ColumnType<T> {
  field: keyof T;
  [index: string]: any;
}

// define your columns
export const getColumns = (token: string): Array<ColumnType<RoleDetails>> => {
  return [
    {
      title: "Roles",
      field: "name",
      cellStyle: cellStyle,
    },
    {
      title: "Users",
      field: "users",
      searchable: false,
      align: "left",
      cellStyle: cellStyle,
      render: (role: RoleDetails) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            {role.users.length}
          </div>
        );
      },
    },
    {
      title: "Description",
      field: "description",
      cellStyle: cellStyle,
    },
    {
      title: "Status",
      field: "status",
      searchable: false,
      align: "left",
      cellStyle: cellStyle,
      render: (role: RoleDetails) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            {(role.status && RoleStatus[role.status]) || role.status}
          </div>
        );
      },
    },
    {
      title: "Date Created",
      field: "createdAt",
      searchable: false,
      align: "left",
      cellStyle: cellStyle,
      render: (role: RoleDetails) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            {new Date(role.createdAt).toLocaleDateString("en-us")}
          </div>
        );
      },
    },
  ];
};
