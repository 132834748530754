import Repository from "./Repository";
import { ProductCategory, ProductCategoryAdd } from "./types/products";
const path = "/product-categories";

const ProductsCategoryRepository = {
  list() {
    return Repository.get<{ productCategories: Array<ProductCategory> }>(
      `${path}`
    );
  },
  create(product: ProductCategoryAdd) {
    return Repository.post<ProductCategory>(`${path}`, product);
  },
};

export default ProductsCategoryRepository;
