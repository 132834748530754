import { NestedRoutes } from "@welborne/component-library";
import { InviteUsers } from "./InviteUsers";
import { UserForm } from "./UserForm";
import { UserTable } from "./UserTable";

export const UserRoutes: NestedRoutes = {
  name: "Users",
  path: "users",
  component: UserTable,
  children: [
    {
      name: "Invite Users",
      path: "inviteUsers",
      component: InviteUsers,
    },
    {
      name: "User",
      path: ":userId",
      children: [
        {
          name: "Edit",
          path: "edit",
          component: UserForm,
        },
      ],
    },
  ],
};
