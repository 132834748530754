import { Form } from "formik";
import styled from "styled-components";

export const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: 20px;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;
  & > div {
    flex-grow: 1;
  }
`;

export const TabContainer = styled.div`
  padding: 20px;
`;
